import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label as StrapLabel } from 'reactstrap';
import { uniqueId } from 'lodash';
import { translate, camelCaseToTile } from './../../../utils/utils';
import { Label } from './common';

const Checkbox = ({ label,labelTop, onChange, className, caption, style, checked, disabled }) => {
  // TODO: Переделать на Component и записывать id в стейт, чтобы он не перегенерировался каждый раз.
  const id = uniqueId('f-element-checkbox-');

  const setCaption = () => {
    if (caption !== '') {
      return translate({ id: caption });
    }

    return caption;
  };

  const labelElement = typeof label==="string"
      ? <span>{' '}{translate({ id: label, defaultMessage: camelCaseToTile(label) })}</span>
      : typeof label==="object" ? label : null;

  return (
    <div className="f-element f-element-checkbox">
      {setCaption()}
      <FormGroup check className={`f-control form-group ${className}`} style={style}>
        <StrapLabel check className="form-checkbox-label">
          {labelElement}
          <Input
            type="checkbox"
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />
          <span className="checkmark" />
        </StrapLabel>
      </FormGroup>
    </div>
  );
};

Checkbox.defaultProps = {
  caption: '',
  className: '',
  style: { marginBottom: '0' },
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  style: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Checkbox;
