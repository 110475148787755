/* eslint-disable */
import React, {Fragment, useEffect, useState} from 'react';
import { uniqueId } from 'lodash';
import { Button, Col, Row, Label } from 'reactstrap';
import moment from "moment";
import {
    getCargotypeLogoCssClass,
    getOrderMiniCardTransportTypeCssClass,
    AgoraDocActions,
    AgoraStatusActions, getOrderStatusName, getStartDateOfOrder, getFinishDateOfOrder
} from './OrderCommon';
import './orders.css';
import {translate} from "../../utils/utils";
import userStore from "../../stores/userStore";
import {ADMIN_DELIVERY_ORDERS, CLIENT_DELIVERY_ORDERS, PARTNER_DELIVERY_ORDERS} from "../../constants/routes";
import {ORDER_DELIVERY_STATUS_MANUAL} from "../../constants/global";
import OrderDeliveryService from '../../services/orderDeliveryService';
import UserStore from '../../stores/userStore';

const DATE_TIME_FORMAT = 'DD.MM.YYYY';
const defaultStrVal = '-';

const getLink = () => {
    if (userStore.isClient()) {
        return CLIENT_DELIVERY_ORDERS;
    }
    if (userStore.isPartner()) {
        return PARTNER_DELIVERY_ORDERS;
    }
    return ADMIN_DELIVERY_ORDERS;
};

const getActions = (canEdit,
  onPopupChangeOwner,
  orderId,
  onClickNavToOrder) => {
    const res = [{
            type: 'details',
            title: 'Подробнее',
            onClick: (id) => {
                location.href = `${getLink()}/${id}`;
            }
        }
    ];

    if (!!canEdit) {
        res.push({
            type: 'edit',
            title: 'Редактировать',
            onClick: (id, callback) => {
                if (callback && typeof callback === 'function'){
                    callback(id);
                }
            }
        });
    }

  OrderDeliveryService.checkChangeOwner(orderId).then(resp=>{
    if(resp.code===200){
      if(resp.data===true){
        res.push({
          type: 'editOwner',
          title: 'Изменить владельца',
          onClick: (id) => {
            if (onPopupChangeOwner && typeof onPopupChangeOwner === 'function'){
              onPopupChangeOwner(id);
            }
          }
        });
      }
    }
  });

  if(UserStore.isManagerOrGreater()){
    res.push({
      type: 'moveToCalculation',
      title: "Показать расчет",
      onClick: (id) => {
        if(onClickNavToOrder && typeof onClickNavToOrder === 'function'){
          onClickNavToOrder(id);
        }
      }
    })
  }
    return res;
};

const OrderLocationPoint = ({title, subtitle, transportType}) => {
    return <div className={'order-m-card-location-info'}>
        <div className={getOrderMiniCardTransportTypeCssClass(transportType)}></div>
        <div>
            <div>{title}</div>
            <div className={'subtitle pale-font'}>{subtitle}</div>
        </div>
    </div>;
};

const OrderMobileMiniCard = (props) => {
    const {
        orderNumber
        , orderDate
        , price
        , currency
        , cargoTypeId
        , cargoTypeName
        , generalTypeId
        , cargoAmount
        , cargoDimensionsValue
        , cargoWeight
        , statusId
        , statusName
        , isSubscribed
        , fromTitle
        , fromSubtitle
        , toTitle
        , toSubtitle
        , isShortenInfo
        , fromTransportType
        , toTransportType
        , unitName
        , cargoWeightUnit
        , cargoDimensionsUnit
        , onStatusEditClick
        , onEditClick
        , canEdit
        , customerCompanyName
        , onPopupChangeOwnerClick
        , onClickToNavOrder
    } = {...props};

    const [trackingInfo, setTrackingInfo] = useState()
    const [loadingDate, setLoadingDate] = useState()
    const [deliveryDate, setDeliveryDate] = useState()
    const [arrivalDate, setArrivalDate] = useState()
    const [shippingDate, setShippingDate] = useState()

    const getArrivalDate = (trackingInfo)=>{
        return (trackingInfo && trackingInfo.pointOfDestination && trackingInfo.pointOfDestination.arrival &&
            trackingInfo.pointOfDestination.arrival.plan) ? trackingInfo.pointOfDestination.arrival.plan : null
    }

    const getShippingDate = (trackingInfo)=>{
        return (trackingInfo && trackingInfo.pointOfPickup && trackingInfo.pointOfPickup.ready && trackingInfo.pointOfPickup.ready.plan) ?
            trackingInfo.pointOfPickup.ready.plan : null
    }

    useEffect(() => {
        OrderDeliveryService.getMinStatusAndTrackingInfo(orderNumber).then(res=>{
            setTrackingInfo(res.data.trackingInfo)
            setLoadingDate(getStartDateOfOrder(res.data.trackingInfo))
            setDeliveryDate(getFinishDateOfOrder(res.data.trackingInfo))
            setArrivalDate(getArrivalDate(res.data.trackingInfo))
            setShippingDate(getShippingDate(res.data.trackingInfo))
        })
    }, [orderNumber]);
    const getCssClassForStatusLabel = (statusId) => {
        switch (statusId) {
            case 1: return 'status-label-in-transit';
            case 2: return 'status-label-delivered';
            case 3: return 'status-label-not-delivered';
            default: return ''
        }
    };
    const getPointToCssClass = (statusId) => {
        switch (statusId) {
            case 1: return 'card-path-point-marker disabled';
            case 2: return 'card-path-point-marker';
            case 3: return 'card-path-point-marker not-delivered';
            default: return 'card-path-point-marker'
        }
    };

    const onCardClick = (id) => {
        location.href = `${getLink()}/${id}`;
    };

    const statusCssClass = `${getCssClassForStatusLabel(statusId)} cargo-status-label`;
    const getDetailedCard = () => {
        const orderLocation = (
            <Fragment>
                <div>
                    <div>
                        <div className={'card-path-point-marker'}></div>
                        <div className={statusId !== 2? 'card-path-line-border': 'card-path-line-border active'}></div>
                    </div>
                    <div className={'location-title-first'}>
                        <div className={'main-title'}>{fromTitle}</div>
                        <div className={'subtitle'}>{fromSubtitle}</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={statusId !== 2? 'card-path-line-border card-path-line-border-point-to': 'card-path-line-border card-path-line-border-point-to active'}></div>
                        <div className={getPointToCssClass(statusId)}></div>
                    </div>
                    <div>
                        <div className={statusId !== 2? 'main-title-pale-font': 'main-title'}>{toTitle}</div>
                        <div className={'subtitle'}>{toSubtitle}</div>
                    </div>
                </div>
            </Fragment>
        );
        const isFloat = (n) =>{
            return Number(n) === n && n % 1 !== 0;
        }
        return <div className={'order-m-card-mobile'}>
            <Row key={uniqueId()} onClick={() => onCardClick(orderNumber)}>
                <div className={'minicard-mobile-title pale-font'}>{customerCompanyName}</div>
            </Row>

            <Row key={uniqueId()} className={'order-m-card-mobile'}>
              <Col xs={10} onClick={() => onCardClick(orderNumber)}>
                <div className={'m-card-order-location'} onClick={() => onCardClick(orderNumber)}>{orderLocation}</div>
              </Col>
              <Col xs={2}>
                <div className={'minicard-right-item'}>
                    <AgoraDocActions actions={getActions(canEdit, onPopupChangeOwnerClick, orderNumber, onClickToNavOrder)} itemId={orderNumber} callback={onEditClick}/>
                </div>
                <div className={'minicard-right-item'}>
                    <div className={isSubscribed? 'order-subscribed': 'order-without-subscribe'}></div>
                </div>
                </Col>
            </Row>
            <Row key={uniqueId()} onClick={() => onCardClick(orderNumber)}>
              <Col xs={6}>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.orderNumber'})}</div>
                    <div>{orderNumber}</div>
              </Col>
              <Col xs={6}>
                <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.loadingDate'})}:</div>
                <div>{loadingDate? moment(loadingDate).format(DATE_TIME_FORMAT): '-'}</div>
            </Col>
            </Row>
            <Row key={uniqueId()} onClick={() => onCardClick(orderNumber)}>
              <Col xs={6}>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.orderDate'})}:</div>
                    <div>{orderDate? moment(orderDate).format(DATE_TIME_FORMAT): '-'}</div>
              </Col>
              <Col xs={6}>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.arrivalDate'})}:</div>
                    <div>{deliveryDate? moment(deliveryDate).format(DATE_TIME_FORMAT): '-'}</div>
            </Col>
            </Row>
            <Row key={uniqueId()} onClick={() => onCardClick(orderNumber)}>
              <Col xs={6}>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.totalPrice'})}:</div>
                    <div>{price} {currency}</div>
              </Col>
              <Col xs={6}>
                <div className={'pale-font'}>
                {translate({ id: 'deliveryOrders.listItem.amount'})}
                </div>
                <div>{cargoAmount || defaultStrVal}</div>
            </Col>
            </Row>
            <Row key={uniqueId()} onClick={() => onCardClick(orderNumber)}>
              <Col xs={6}>
              <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.cargoType'})}</div>
                    <div>
                        <div className={getCargotypeLogoCssClass(cargoTypeId)}></div>{cargoTypeName || defaultStrVal}
                    </div>
              </Col>
              <Col xs={6}>
                <div className={'pale-font'}>
                    {generalTypeId === 3 ?
                        translate({id: 'deliveryOrders.listItem.totalVolume'})
                        :
                        translate({ id: 'deliveryOrders.listItem.size'})}, {cargoDimensionsUnit}
                </div>
                <div>{cargoDimensionsValue}</div>
            </Col>
            </Row>
            <Row key={uniqueId()}>
              <Col xs={6} onClick={() => onCardClick(orderNumber)}>
                <div className={'pale-font'}>
                    {translate({ id: 'deliveryOrders.listItem.weight'})}, {cargoWeightUnit}
                </div>
                <div>{isFloat(cargoWeight)?parseFloat(cargoWeight).toFixed(2):cargoWeight}</div>
              </Col>
              <Col xs={6}>
                <div className='pale-font'>
                    {translate({ id: 'deliveryOrders.listItem.status'})}
                </div>
                <div>
                    <AgoraStatusActions
                    actions={getStatusActions(onStatusEditClick)}
                    itemId={orderNumber}
                    statusId={statusId}
                    statusName={statusName}
                /></div>
            </Col>
            </Row>
        </div>
    };

    const getStatusActions = (onStatusChange = false) => {
        const res = Array.from(ORDER_DELIVERY_STATUS_MANUAL.keys()).map((s) => {
            const item = {
                type: s,
                title: getOrderStatusName(s),
                onClick: (id) => {
                    console.log('Status Edit', id, s);
                    if (onStatusChange && typeof onStatusChange === 'function') {
                        onStatusChange(id, s);
                    }
                },
            };
            return item;
        });
        return res;
    };

    const getShortenCard = () => {
        return (
            <tr key={orderNumber}>
                <td>{orderNumber}</td>
                <td>
                    <OrderLocationPoint
                        title={fromTitle}
                        subtitle={fromSubtitle}
                        transportType={fromTransportType}
                    />
                </td>
                <td>
                    <OrderLocationPoint
                        title={toTitle}
                        subtitle={toSubtitle}
                        transportType={toTransportType}
                    />
                </td>
                <td>
                    <div>{cargoAmount} {unitName}</div>
                    <div className={'pale-font subtitle'}>{cargoDimensionsValue}</div>
                </td>
                <td>
                    {price} {currency}
                </td>
                <td><span className={statusCssClass}>{statusName}</span></td>
                <td>
                    <div className={'minicard-actions'}>
                        <div className={'minicard-right-item'}>
                            <div className={isSubscribed? 'order-subscribed': 'order-without-subscribe'}></div>
                        </div>
                        <div className={'minicard-right-item'}>
                            <AgoraDocActions actions={getActions(canEdit, onPopupChangeOwnerClick, orderNumber, onClickToNavOrder)} itemId={orderNumber} />
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    return isShortenInfo? getShortenCard(): getDetailedCard();
};

export {
    OrderMobileMiniCard
};
