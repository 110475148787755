import React, {useEffect, useState,useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, FormGroup, Label, Row} from "reactstrap";
import {AllHubsSelect, DateInput, Select, TextInput} from "../ui/form-control";
import {cloneDeep, debounce, get, set} from "lodash";
import UserStore from "../../stores/userStore";
import {FormattedMessage} from "react-intl";
import NumberInput from "../ui/form-control/NumberInput";
import {
    addFormatName,
    addFormatNameToCity,
    addFormatNameWithCode,
    addFormatNameWithHub,
    translate
} from "../../utils/utils";
import DictionaryService from "../../services/dictionaryService";
import ChangeOwnerOrderModal from "../Orders/ChangeOwnerOrderModal";
import AppStore from "../../stores/appStore";
import {FakeOrderPriceBuildComponent} from "../ui/FakePrices/FakeOrderPriceBuildComponent";
import dictionaryService from "../../services/dictionaryService";
import FontAwesome from "react-fontawesome";

const RouteSegment = ({
                          item = {type: null, hub: null},
                          onChange = () => {
                          },
                          onDelete = () => {
                          },
                          index = 0,
                          errors,
                          onErrorDelete=(name)=>{},
                          typeCargo,
                          isInternational,
                          isAllowCustomClearance
                      }) => {
    const typesHub = [
        {value: "SEA_PORT", name: translate({id: "SEA_PORT"})},
        {value: "RAILWAY_HUB", name: translate({id: "RAILWAY_HUB"})},
        {value: "AIRPORT_HUB", name: translate({id: "AIRPORT_HUB"})},
        {value: "TRANSPORT_HUB", name: translate({id: "TRANSPORT_HUB"})},
        {value: "CUSTOMS_CLEARANCE", name: translate({id: "CUSTOMS_CLEARANCE"})}
    ]
    const [hubs, setHubs] = useState([])
    const [disabledSelectHub, setDisabledSelectHub] = useState(false)
    const [allowTypeHubs, setAllowTypeHubs] = useState([])

    useLayoutEffect(()=>{
        setAllowTypeHubs(getTypeHubsByTypeCargo(typeCargo, isInternational, isAllowCustomClearance))
    }, [isInternational, typeCargo, isAllowCustomClearance])

    useEffect(() => {
        setHubs([])
    }, [item?.type])

    const _loadOptions = (input) => {
        if (item?.type !== null) {
            switch (item?.type) {
                case "SEA_PORT":
                    dictionaryService.getSeaPortHubsWithLimit({name: input}).then(res => {
                        const arr = [];
                        if (item?.hub !== null) {
                            arr.push(item.hub)
                        }
                        arr.push(...addFormatNameToCity(res))
                        setHubs(arr)
                    })
                    break;
                case "RAILWAY_HUB":
                    dictionaryService.getRailwayHubsWithLimit({name: input}).then(res => {
                        const arr = [];
                        if (item?.hub !== null) {
                            arr.push(item.hub)
                        }
                        arr.push(...addFormatNameWithHub(res))
                        setHubs(arr)
                    })
                    break;
                case "AIRPORT_HUB":
                    dictionaryService.getAirportHubsWithLimit({name: input}).then(res => {
                        const arr = [];
                        if (item?.hub !== null) {
                            arr.push(item.hub)
                        }
                        arr.push(...addFormatNameWithCode(res))
                        setHubs(arr)
                    })
                    break;
                case "TRANSPORT_HUB":
                    dictionaryService.getTransportHubsWithLimit({name: input}).then(res => {
                        const arr = [];
                        if (item?.hub !== null) {
                            arr.push(item.hub)
                        }
                        arr.push(...addFormatName(res))
                        setHubs(arr)
                    })
                    break;
            }
        }
    }
    const loadOptions = debounce(_loadOptions, 300)

    const getTypeHubsByTypeCargo=(typeCargo, international, isAllowCustomClearance)=>{
        const typesHubCloned = typesHub.filter(v=> isAllowCustomClearance===true?international===true?true:v.value!=="CUSTOMS_CLEARANCE":v.value!=="CUSTOMS_CLEARANCE")
        switch (typeCargo) {
            case "containers":
                return typesHubCloned.filter(value => value.value!=="AIRPORT_HUB")
            case "box":
                return typesHubCloned
            case "car":
                return typesHubCloned.filter(value => !["AIRPORT_HUB", "RAILWAY_HUB", "SEA_PORT"].includes(value.value))
            default:
                return []

        }
    }

    return (
        <div className={"mt-2"}>
            <Row className={"align-items-center"}>
                <Col className={"d-flex align-items-center w-100 pl-0 pr-0"}>
                    <FormattedMessage id={"orderBlock.hubNumber"}/> {index}
                    <Button onClick={onDelete} className={"btn-outline-danger ml-auto"}>
                        <FontAwesome name={"trash"}/>
                    </Button>
                </Col>
            </Row>
            <Row className={"align-items-start"}>

                <Col className={"pl-0 pr-0 pr-md-2"} xs={12} md={4} lg={3}>
                    <Select
                        label={<span className={'f-label flag-required'}
                                     style={{marginBottom: '5px'}}><FormattedMessage
                            id={'orderBlock.selectTypeHub'}/></span>}
                        values={allowTypeHubs}
                        value={item?.type}
                        onChange={value => {onChange({type: value?.value, hub: null}); onErrorDelete(`route[${index-1}].type`);}}
                        valueKey={"value"}
                        labelKey={"name"}
                        clearable={false}
                        error={get(errors, `route[${index-1}].type`)}
                    />
                </Col>
                <Col className={"pl-0 pr-0"} xs={12} md={8} lg={9}>
                    {item?.type!=="CUSTOMS_CLEARANCE" &&
                    <Select
                        className={"w-100"}
                        label={<span className={'f-label flag-required'}
                                     style={{marginBottom: '5px'}}><FormattedMessage
                            id={"orderBlock.selectHub"}/></span>}
                        disabled={item?.type === null}
                        searchable={true}
                        filterOption={(option, value) => true}
                        values={hubs}
                        onInputChange={loadOptions}
                        value={item?.hub}
                        labelKey={"formatName"}
                        valueKey={"id"}
                        onChange={hub => {onChange({type: item?.type, hub: hub}); onErrorDelete(`route[${index-1}].hub`)}}
                        error={get(errors, `route[${index-1}].hub`)}
                    />
                    }
                </Col>
            </Row>
        </div>
    )
}
const RouteBuildComponent = ({
                                 route = [],
                                 setRoute = () => {
                                 },
                                 errors={},
                                 onErrorDelete=(name)=>{},
                                 typeCargo,
                                 disabledAddButton,
                                 isInternational
                             }) => {
    const onChange = (item, index = null) => {
        let cloneRoute = cloneDeep(route)
        if (index === null) {
            cloneRoute.push(item)
        }
        if (item === null) {
            cloneRoute = cloneRoute.filter((item, i) => i !== index);
            onErrorDelete(`route[${index}].type`)
            setTimeout(() => {
                onErrorDelete(`route[${index}].hub`)
            }, 150)
        } else {
            cloneRoute[index] = item
        }
        setRoute(cloneRoute)
    }
    const isAllowCustomClearance=(array=[], currentIndex)=>{
        const indexCustom = array.findIndex(v=>v.type==="CUSTOMS_CLEARANCE")
        if(indexCustom===-1)
            return true
        if(currentIndex===indexCustom)
            return true
        return false
    }
    return (
        <div>
            {route.map((item, index,array) => {
                    return (
                        <div>
                            <RouteSegment
                                item={item}
                                onChange={(value) => onChange(value, index)}
                                onDelete={() => onChange(null, index)}
                                index={index+1}
                                errors={errors}
                                onErrorDelete={onErrorDelete}
                                typeCargo={typeCargo}
                                isInternational={isInternational}
                                isAllowCustomClearance={isAllowCustomClearance(array, index)}
                            />
                        </div>
                    )
                }
            )}

                <Button className={"btn-primary-new"} onClick={() => onChange({type: null, hub: null})}><FormattedMessage id={"orderBlock.addTransitHub"}/></Button>

        </div>)
}
const OrderDetailsBlock = ({data, onSave, errors, onErrorDelete, toCountry,typeCargo,fields,isInternational}) => {
    const defaultCurrency = AppStore.userLang === "ru" ? "RUB" : "USD"
    const [disableCompany, setDisableCompany]=useState(false)
    const [disablePhone, setDisablePhone]=useState(false)
    const [currencies, setCurrencies] = useState([])
    const [transitHubs, setTransitHubs] = useState([])
    const [transitHub, setTransitHub] = useState(null)
    const [newOwner, setNewOwner]=useState(null)
    const [transportationTypes, setTransportationTypes] = useState([])
    const [transportationTypeCode,  setTransportationTypeCode] = useState(null)
    const [route, setRoute] = useState([])
    const [prices, setPrices] = useState([])
    const [cargoArray, setCargoArray] = useState([])
    const onChange = (name, value) => {
        const newData = cloneDeep(data)
        set(newData, name, value)
        /* if (name === "price.value")
             onErrorDelete(`price`)
         else
             onErrorDelete(`shortOrderRequestDetailsParam.${name}`)*/
        onSave(newData)
    }

    const onDeleteErrorRoute=(name)=>{
        onErrorDelete(name)
    }

    useEffect(()=>{
        onChange("prices", prices)
    }, [prices])

    // useEffect(() => {
    //     setTransportationTypeCode(null)
    //     setPrices((cloneDeep(prices).map(price => {return{
    //         ...price,
    //         typeService: null
    //     }})))
    // }, [typeCargo]);

    useEffect(()=>{
        const newData = cloneDeep(data)
        set(newData, 'route', route)
        onSave(newData)
    }, [route])

    /*useEffect(()=>{
        console.log("fields.cargo", prices.filter(v=>(typeCargo==="containers"?fields.cargo.containers:typeCargo==="box"?fields.cargo.generals:[fields.cargo.vehicle]).filter(cargo=>cargo.idCargo===v.idCargo).length>0))
        if(prices.length>0)
            setPrices(prices.filter(v=>(typeCargo==="containers"?fields.cargo.containers:typeCargo==="box"?fields.cargo.generals:[fields.cargo.vehicle]).filter(cargo=>cargo.idCargo===v.idCargo).length>0))
    }, [fields])*/


    useEffect(() => {
        if(UserStore.user.organization){
            const newData = cloneDeep(data)
            if(UserStore.user.organization.name) {
                newData.companyName = UserStore.user.organization.name
                setDisableCompany(true)
            }
            if(UserStore.user.organization.phone) {
                newData.phone = UserStore.user.organization.phone
                setDisablePhone(true)
            }
            onSave(newData)
        }
        DictionaryService.getCurrencies().then(res=>{
            setCurrencies(res)
        })
    }, []);

    useEffect(()=>{
        DictionaryService.getAllHubs().then(res=>{
            const newData = res.filter(v=>v.country.code.toString()===toCountry.toString()||v.country.id.toString()===toCountry.toString())
            setTransitHubs(newData)
        })
    }, [toCountry])
    useEffect(() => {
        DictionaryService.getTransportationCargoTypes().then(res=>{
            setTransportationTypes(addFormatName(res));
        })
    }, []);
    const getTypesByTypeCargo = (values) => {

        if (values.length > 0) {
            const newArray = values.reduce((acc, item) => {
                const str = item.cargoTypes;
                const cleanStr = str.slice(1, -1).trim();
                const arr = cleanStr.split(',').map(s => s.trim().toLowerCase());

                if (arr.includes(typeCargo)) {
                    if (fields.fromLocation.type === fields.toLocation.type) {
                        if (fields.fromLocation.type !== 'ADDRESS' && fields.toLocation.type !== 'ADDRESS') {
                            if (fields.fromLocation.type === item.directions.from && fields.toLocation.type === item.directions.to) {
                                acc.push(item);
                            }
                        } else {
                            acc.push(item);
                        }
                    } else {
                        acc.push(item);
                    }
                }

                return acc;
            }, []);

            return newArray;
        }
    }
    const getError=(name)=>{
        if(errors){
            return get(errors, name, null)
        }
        return null
    }



    const onChangeOwner=(nameField, valueField)=>{
        onChange(`newOwner.${nameField}`, valueField)
    }
    useEffect(()=>{
        setCargoArray(typeCargo==="containers"?fields.cargo.containers:typeCargo==="box"?fields.cargo.generals:[fields.cargo.vehicle])
    }, [typeCargo, fields])

    return (
        <Container className={"block-margin-top"}>
            <div className={"details-order-param"}>
                <Row>
                    <Col xs={12}>
                        <h1><FormattedMessage id={"order.createNewManual.detailsOrder.title"}/></h1>
                    </Col>
                </Row>
                <Row>


                <Col xs={12} md={6} lg={4}>
                    <FormGroup>
                        <Select
                            disabled={false}
                            values={getTypesByTypeCargo(transportationTypes)}
                            value={transportationTypeCode}
                            labelKey={"formatName"}
                            valueKey={"uid"}
                            label={<span className={'f-label flag-required'}
                                         style={{marginBottom: '5px'}}><FormattedMessage
                                id={'crud.field.transportationType.title'}/></span>}
                            onChange={value => {
                                setTransportationTypeCode(value.uid);
                                onChange('transportationTypeCode', value.uid)
                            }}
                            className="crud-select"
                            error={getError("typeTransportation")}
                            clearable={false}
                        />

                    </FormGroup>
                </Col>
                    <Col xs={12} className={"mt-2"}>
                        <RouteBuildComponent route={route}
                                             typeCargo={typeCargo}
                                             setRoute={setRoute}
                                             errors={errors}
                                             isInternational={isInternational}
                                             disabledAddButton={transportationTypeCode === null || transportationTypeCode === undefined}
                                             onErrorDelete={onDeleteErrorRoute}

                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                disabled={disableCompany}
                                error={getError("shortOrderRequestDetailsParam.companyName")}
                                value={data.companyName}
                                onChange={(ev)=>onChange("companyName", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"orderForm.companyName"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                disabled={disablePhone}
                                error={getError("shortOrderRequestDetailsParam.phone")}
                                value={data.phone}
                                onChange={(ev)=>onChange("phone", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"form.field.phone"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <TextInput
                                value={data.cargoDescription}
                                error={getError("shortOrderRequestDetailsParam.cargoDescription")}
                                onChange={(ev)=>onChange("cargoDescription", ev.target.value)}
                                label={<span className={"f-label flag-required"}><FormattedMessage id={"form.field.cargoName"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormGroup>
                            <DateInput
                                placeholder={"form.order.shippingDatePlan"}
                                value={data.dateReadyCargo.plan}
                                onChange={(ev)=>onChange("dateReadyCargo.plan", ev)}
                                label={<span className={"f-label"}><FormattedMessage id={"form.order.shippingDatePlan"}/></span>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <TextInput
                                value={data.remarks}
                                placeholder={"form.order.remarks"}
                                onChange={(ev)=>onChange("remarks", ev.target.value)}
                                label={<span className={"f-label"}><FormattedMessage id={"form.order.remarks"}/></span>}
                                type={"textarea"}
                            />
                        </FormGroup>
                    </Col>
                    {/*<Col xs={12} lg={8}>
                        <FormGroup className={"mb-0"}>
                            <Label className={"f-label flag-required"}><FormattedMessage
                                id={"deliveryOrders.listItem.totalPrice"}/></Label>
                            <div className={"d-flex"}>
                                <NumberInput
                                    error={getError("price")}
                                    value={data.price.value}
                                    placeholder={"crud.field.price.title"}
                                    onChange={value => onChange("price.value", value)}
                                />
                                <div style={{width: "80px"}} className={"ml-3"}>
                                    <Select
                                        multi={false}
                                        values={currencies}
                                        labelKey="code"
                                        valueKey="code"
                                        value={data.price.currency}
                                        onChange={el => onChange('price.currency', el.code)}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        </FormGroup>
                    </Col>*/}
                    <Col xs={12} className={"mt-3"}>
                        <FakeOrderPriceBuildComponent
                            typeCargo={typeCargo}
                            currencies={currencies}
                            prices={prices}
                            setPrices={setPrices}
                            errors={errors}
                            onErrorDelete={onErrorDelete}
                            cargoArray={cargoArray}
                        />
                    </Col>
                </Row>
                <div className={"mt-0 mt-md-3"}>
                    <ChangeOwnerOrderModal isBlock={true} onChange={onChangeOwner}/>
                </div>
            </div>
        </Container>
    );
};

OrderDetailsBlock.propTypes = {};

export default OrderDetailsBlock;
