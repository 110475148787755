import React, {Fragment, useEffect, useState} from "react";
import Fa from "react-fontawesome";
import {Input} from "reactstrap";

/**
 * In-place Number Input
 *
 * @param {Object} props - The properties object for the component.
 * @param {type} props.value - The initial value for the input.
 * @param {function} props.onSave - The function to call when the input value is saved.
 * @param {string} [props.rightAddon=''] - The label to display on the right side of the input.
 * @param {boolean} [props.disabled=false] - Whether the input is disabled.
 * @param {...rest} - Any other properties to be spread onto the container div.
 * @return {JSX.Element} - The rendered component.
 */
const NumberInputInplace = (props) => {
    const {value, onSave, leftAddon= '', rightAddon = '', disabled=false, ...rest} = props;
    const [currentValue, setCurrentValue] = useState(value);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setCurrentValue(value);
    },[value, editMode]);


    const handleSave = () => {
        setEditMode(false);
        if (currentValue !== value) onSave(currentValue);
    }

    const handleCancel = () => {
        setEditMode(false);
    }

    const handleEdit = () => {
        if (!disabled) setEditMode(true);
    }
    const handleChange = (e) => {
        setCurrentValue(e.target.value);
    }

    const styles = {
        container: {
            fontSize: '18px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: '0.5em',
        },
        label: {
            /*fontWeight: 'bold',*/
            /*color: 'var(--primary-active-color, #4996ff)',*/
            color: 'var(--primary-active-color, #4996ff)',
            border: '1px solid var(--primary-active-color, #4996ff)',
            padding: '8px',
            lineHeight: '16px',
            width: 'auto',
            textAlign: 'center',
            cursor: 'pointer',
        },
        labelRight: {
            paddingLeft: '0.3em',
        },
        button: {
            cursor: 'pointer',
            paddingLeft: '0.5em',
        },
        buttonEdit: {
          color: 'var(--primary-active-color, #4996ff)',
        },
        buttonSave: {
          color: 'green',
          fontSize: '24px',
        },
        buttonCancel: {
          color: 'red',
          fontSize: '24px',
        },
        input: {
            width: '90px',
            color: 'var(--primary-active-color, #4996ff)',
            border: '1px solid var(--primary-active-color, #4996ff)',
            lineHeight: '16px',
            fontSize: '18px',
        },
        disabled: {
            color: 'var(--gray, #6c757d)',
            borderColor: 'var(--gray, #6c757d)',
            cursor: 'not-allowed',
        },
        leftAddon: {
            color: '#8D98A6',
            marginRight: '10px',
        }
    }

    return (
        <div style={{...styles.container}} {...rest}>
        { leftAddon && <span style={{...styles.leftAddon}}>{leftAddon}</span> }
        {editMode
            ? <Fragment>
                {/* render on edit mode */}
                <Input type='number' bsSize="sm" value={currentValue} onChange={handleChange} style={{...styles.input}}/>
                <Fa name="check" onClick={handleSave} style={{...styles.button, ...styles.buttonSave}} />
                <Fa name="ban" onClick={handleCancel} style={{...styles.button, ...styles.buttonCancel}} />
            </Fragment>
            : <Fragment>
                {/* render on display mode */}
                <span onClick={handleEdit} style={{...styles.label, ...(disabled ? styles.disabled : {})}}>{value}{rightAddon && ` ${rightAddon}`}</span>
                {/*<Fa name="edit" onClick={handleEdit} style={{...styles.button, ...styles.buttonEdit}} />*/}
            </Fragment>

        }
        </div>
    );

}

export default NumberInputInplace;
