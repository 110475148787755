/* eslint-disable */
/* eslint-disable arrow-parens */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import OrderDocs from "../../Orders/OrderCardOrderDocs";
import { OrderMobileMiniCard } from '../../Orders/OrderMobileMiniCard';
import { Button, Col, Row, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { forEach, forOwn, get, cloneDeep, isFunction, isArray, isNumber, isBoolean, compact, round, debounce } from 'lodash';
import { observer } from 'mobx-react';
import FontAwesome from 'react-fontawesome';
import { toJS } from 'mobx';
import { camelCaseToTile, confirm, keyGenerator, translate, getLangInStorage, getExistLangValue } from '../../../utils/utils';
import { Checkbox } from '../form-control';
import CrudPagination from './CrudPagination';
import { WaitingStatus } from '../../common/Statuses';
import FlashStore from '../../../stores/flashStore';
import FileService from '../../../services/fileService';
import {
  getCargoTypeIdByCargoInfo, getFinishDateOfOrder, getStartDateOfOrder,
  getCargoSummaryForOrder, prepareOrderCardCargoInfoData, getOrderStatusNameById, getOrderStatusName
} from '../../Orders/OrderCommon';
import { API } from '../../../constants/api';
import userStore from '../../../stores/userStore';
import orderDeliveryService from "../../../services/orderDeliveryService";
import ChangeOwnerOrderModal from '../../Orders/ChangeOwnerOrderModal';
import CreateFakeOrderForm from "../ModalOrder/CreateFakeOrderForm";
import UserStore from '../../../stores/userStore';

@observer
class CrudListMobile extends Component {
  state = {
    loaded: false,
    isShow: false,
    item: null,
    isAllChecked: false,
    searchString: '',
    currentIndex: 0,
    showChangeOwnerOrderModal:false,
    documentModalClosed: false,
  };

  componentDidMount() {
    this.loadItems();
    this.props.store.bulkStore.setEditItems([]);
  }

  componentDidUpdate(prevProps) {
    if(this.props.isUpdated === true){
      const {store} = this.props;
      store.reloadList();
      this.props.isUpdateFn()
    }
  }

  async handleEdit(item, readOnly) {
    const { store } = this.props;

    await store.edit(item ? item.id : null, readOnly);
  }
  setStatus =  (id, status) => {
    orderDeliveryService.setStatus(id, status)
        .then( async (response) => {
          if (response.status === 'success') {
            FlashStore.successNow(translate({ id: 'common.save.success' }), true);
            debugger;
            const {store} = this.props;
            await store.reloadList();
          } else {
            FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
          }
        });
  };

  async handleBulkOperations() {
    const { store } = this.props;

    await store.bulkStore.setEditItems(store.bulkStore.bulkEditItems);
    await store.bulkStore.startBulkOperations();
  }

  async handleDelete(item) {
    const { store, enableNotifications, customRemoveMessage } = this.props;

    confirm(translate({ id: customRemoveMessage?customRemoveMessage:'crud.remove.confirm.text' }), async () => {
      const deletedItem = await store.remove(item.id);

      await store.reloadList();
      if(this.props.isManageSubscriptionPage){
        this.props.onReloadData()
      }

      if (enableNotifications && deletedItem.response.status === 'success') {
        FlashStore.successNow(translate({ id: 'crud.remove.success' }), true);
      } else if (isArray(deletedItem.response.data.errors)) {
        deletedItem.response.data.errors.forEach((err) => {
          if (enableNotifications) {
            FlashStore.errorNow(translate({ id: err.errorText }), true);
          }
        });
      } else if (enableNotifications) {
        FlashStore.errorNow(translate({ id: 'crud.remove.failed' }), true);
      }
    });
  }

  async handleColumnClick(column) {
    const { store } = this.props;

    await store.switchColumnSorting(window.location.pathname.split('/').join(','), column.name);
  }

  onSearch = (e) => {
    this.setState({
      ...this.state,
      searchString: e.target.value,
    });
    this.searchAction(e.target.value);
  };

  searchKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (e.target.value) {
        this.searchAction(e.target.value);
      }
    }
  };

  searchAction = debounce(async(searchValue) => {
    this.setState({ loaded: false });
    const { store } = this.props;
    store.defaultListParams.searchValue = searchValue || this.state.searchString;
    store.loadCollectionView(window.location.pathname.split('/').join(','));
    store.list(false, false, true).then(() => this.setState({ loaded: true }));
  }, 2000 );

  async loadItems() {
    const { store } = this.props;

    if (store.hasService()) {
      await store.loadCollectionView(window.location.pathname.split('/').join(','));
      await store.list();
    } else {
      console.warn('CrudList with static data!');
    }

    this.setState({ loaded: true });
  }

  async downloadFile(endpoint) {
    const url = API.BASE(`${endpoint}`);
    let filename = `${endpoint.split('/')[2]}-for-${endpoint.split('/')[1]}.xlsx`;
    if (endpoint.split('/')[2] === 'excel') {
      filename = 'zip-code-range-for-road-transport.xlsx';
    }
    await FileService.downloadFile(url, filename);
  }

  getTopControlsButton() {
    const { isAdd, isDocs, bulkOperations, downloadCSV, endpoint } = this.props;

    if (!isAdd && isDocs) {
      return (
        <div className="crud-list__inner_actions">
          <input
            ref={(ref) => { this.fileInput = ref; }}
            id={'load-file-private-another'}
            name="file"
            type="file"
            className="d-none"
            onChange={(e) => this.onCreateDoc(e, 'another')}
          />
          <Label
            for={'load-file-private-another'}
          >
            <div className="new-doc-btn"><FormattedMessage id="crud.add.record" /></div>
          </Label>
        </div>
      );
    }

    if (!isAdd) {
      return null;
    }

    return (
      <div className="crud-list-mobile__inner_actions">
        <Button
          color="primary"
          onClick={() => (this.props.onAdd ? this.props.onAdd() : this.handleEdit(null, false))}
        >
          <FormattedMessage id="crud.add.record" />
        </Button>{' '}
        {
          bulkOperations ?
            <Button
              disabled={this.props.store.bulkStore.bulkEditItems.length === 0}
              color="secondary"
              onClick={() => this.handleBulkOperations()}
            >
              <span>{ translate({ id: 'crud.edit.bulkOperation' }) }{' '}({this.props.store.bulkStore.bulkEditItems.length})</span>
            </Button> : null
        }{' '}
        {
          downloadCSV ?
            <Button
              disabled={this.props.store.items.length === 0}
              color="success"
              onClick={() => this.downloadFile(endpoint)}
            >
              <span>{ translate({ id: 'crud.edit.downloadCSV' }) }</span>
            </Button> : null
        }
      </div>
    );
  }

  startPagination = () => {
    if (this.state.loaded) {
      this.setState({ loaded: false });
    }
  };

  finishPagination = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };

  getPagination() {
    const { isPagination, store, isTrackingBlock } = this.props;

    return isPagination ? <CrudPagination store={store} isOrdersList={isTrackingBlock} startPagination={() => this.startPagination()} finishPagination={() => this.finishPagination()} /> : null;
  }

  handleCheckBulkOperations = (item) => {
    const checked = toJS(this.props.store.bulkStore.bulkEditItems);
    const found = checked.find(el => el === item.id);

    if (found) {
      this.props.store.bulkStore.setEditItems(checked.filter(el => el !== found));
    } else {
      const fields = cloneDeep(checked);

      fields.push(item.id);
      this.props.store.bulkStore.setEditItems(fields);
    }
  };

  handleCheckAll(allItemsOnPage) {
    const { isAllChecked } = this.state;

    if (isAllChecked) {
      this.setState({
        isAllChecked: !isAllChecked,
      });
      this.props.store.bulkStore.setEditItems([]);
    } else {
      this.setState({
        isAllChecked: !isAllChecked,
      });
      this.props.store.bulkStore.setEditItems(toJS(allItemsOnPage).map(el => el.id));
    }
  }


  renderDeleteBtn(item) {
    if(this.props.isManageSubscriptionPage){
      if(item.status === "DECLINE" || item.status === "ACCEPT")
        return null;
    }

    if(this.props.isDelete===false)
      return null

    if (item.deletable === undefined || item.deletable) {
      return (
        <Button
          size="sm"
          color="danger"
          onClick={() => this.handleDelete(item)}
          className="btn-remove-crud-list-mobile"
        />
      );
    }

    return null;
  }

  async downloadDocument(item) {
    const isOrderToLogist = item.kind.uuid === 'ORDER_TO_LOGISTICIAN';
    const url = isOrderToLogist
      ? API.DOCUMENTS.GENERATE_AND_DOWNLOAD_FILE({ item })
      : API.DOCUMENTS.DOWNLOAD_FILE({ item });
    await FileService.downloadFile(url, item.file.filename);
  }

  async sendDocument(item) {
    const { store } = this.props;
    const isOrderToLogist = item.kind.uid === 'ORDER_TO_LOGISTICIAN';
    const res =  isOrderToLogist ? await store.generateAndSendEmail(item.id) : await store.sendEmail(item.id);
    if (res === undefined) {
      FlashStore.successNow(translate({ id: 'documents.email.success' }), true);
    }
  }

  onChangeFile = async (e, item) => {
    const { store } = this.props;
    let file = get(e, 'target.files[0]', null);

    if (file && file.size > 20000000) {
      FlashStore.errorNow(translate({ id: 'common.documents.error.file' }), true);
      file = null;
      this.fileInput.value = '';
    }
    if (file) {
      if (userStore.isOperator() || userStore.user.id === +item.userId) {
        confirm(translate({ id: 'documents.replace.doc' }), async () => {
          await store.updateFile(item.id, file);
          if (item.type === 'ANOTHER') {
            await store.update(item.id, item, file.name);
          }
          FlashStore.successNow(translate({ id: 'trunking.form.uploadSuccess' }), true);
          await store.reloadList();
        });
      }
    }
  };

  onCreateDoc = async (file, type, title) => {
    const { store } = this.props;
  //  let file = get(e, 'target.files[0]', null);
    const orderDeliveryRequestGroupId = (window.location.href.split('/')[window.location.href.split('/').length - 1]);

    if (file && file.size > 20000000) {
      FlashStore.errorNow(translate({ id: 'common.documents.error.file' }), true);
      file = null;
      this.fileInput.value = '';
    }

    await store.create({
      title: title,
      type: 'CUSTOM',
      kind: type,
      file,
      orderDeliveryRequestGroupId,
    }, true)
    .then( response => response.json() )
    .then(() => {
      FlashStore.successNow(translate({ id: 'trunking.form.uploadSuccess' }), true);
    } )
    .catch(err => console.error(err));

    this.setState({
      documentModalClosed: true,
    });
    await store.reloadList();
  };


  fetchColumns() {
    let { children } = this.props;

    const columnHead = [];
    const columns = [];

    children = compact(children); // remove nulls and undefined from children.

    React.Children.map(children, (child) => {
      const { props } = child;
      const { name } = props;
      let { title } = props;

      if (!title && name) {
        title = translate({ id: `crud.field.${name}.title`, defaultMessage: camelCaseToTile(name) });
      } else {
        title = translate({ id: title, defaultMessage: title });
      }


      if (props.isShow) {
        columns[name] = props;
      }

      if (props.isShow) {
        columnHead[name] = title;
      }
    });

    return { columns, columnHead };
  }

  renderCards(items) {
    const { columns, columnHead } = this.fetchColumns();
    const body = [];

    const actions = (item) => {
      if (isFunction(this.props.renderActionsButtons)) {
        return (
          <Row key={'-'} className="row-action">
            <Col xs={12}>
              {this.props.renderActionsButtons({ item, store: this.props.store })}
            </Col>
          </Row>
        );
      }



      if (isNumber(this.props.renderActionsButtons)) {
        if (item.organizationId !== this.props.renderActionsButtons) {
          return (
            <Row key={'-'} className="row-action">
              <Col xs={12}>
                <Button
                  size="sm"
                  onClick={() => this.props.store.edit(item ? item.id : null, true)}
                  className="btn-view-crud-list-mobile mr-2"
                />
              </Col>
            </Row>
          );
        }
      }

      if (isBoolean(this.props.isAdminOnlyView)) {
        if (this.props.isAdminOnlyView) {
          return (
            <Row key={'-'} className="row-action">
              <Col xs={12}>
                <Button
                  size="sm"
                  onClick={() => this.props.store.edit(item ? item.id : null, true)}
                  className="btn-view-crud-list-mobile mr-2"
                />
              </Col>
            </Row>
          );
        }
      }
      if (this.props.isDocs && userStore.isPartner()) {
        // eslint-disable-next-line no-param-reassign
        item.deletable = userStore.user.id === +item.userId;
        // eslint-disable-next-line no-param-reassign
        item.canEdit = userStore.user.id === +item.userId;
      } else {
        // eslint-disable-next-line no-param-reassign
        item.canEdit = true;
      }
      if (this.props.isDocs) {
        return (
          <td key={'-'} className="cell-action d-flex justify-content-center">
            { this.props.isDocs &&
              <Button
                size="sm"
                onClick={() => this.downloadDocument(toJS(item))}
                color="info"
                className="btn-download-crud-list mr-2"
              />
            }
            { this.props.isDocs &&
              <Button
                size="sm"
                onClick={() => this.sendDocument(item)}
                color="success"
                className="btn-email-crud-list mr-2"
              />
            }
            { this.props.isDocs && userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId) &&
              <div>
                <input
                  ref={(ref) => { this.fileInput = ref; }}
                  id={`load-file-private-${item.id}`}
                  name="file"
                  type="file"
                  className="d-none"
                  onChange={(e) => this.onChangeFile(e, item)}
                />
                <Label
                  for={`load-file-private-${item.id}`}
                >
                  <div className="btn-upload-crud-list" />
                </Label>
              </div>
            }
            { this.props.isDocs && userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId) &&
              <Button
                size="sm"
                onClick={() => (this.props.onEdit ? this.props.onEdit(item) : this.handleEdit(item, false))}
                color="success"
                className="btn-view-crud-list mr-2"
              />
            }
            { (userStore.user && (userStore.isOperator() || userStore.user.id === +item.userId)) && this.renderDeleteBtn(item) }
          </td>
        );
      }
      return (
        <Row key={'-'} className="row-action">
          <Col xs={12}>
            {item.needUpdate === true &&
                  <Button
                      size="sm"
                      onClick={() => this.props.onView(item, true)}
                      color="danger"
                      className="btn-update-crud-list mr-2"
                  />
            }
            { !this.props.isOnlyDelAction && !this.props.onView && item.canEdit && this.props.isEdit===true &&
              <Button
                size="sm"
                onClick={() => (this.props.onEdit ? this.props.onEdit(item) : this.handleEdit(item, false))}
                color="info"
                className="btn-view-crud-list-mobile mr-2"
              />
            }
            { !this.props.isOnlyDelAction && this.props.onView && item.canEdit && this.props.isEdit===true &&
              <Button
                size="sm"
                onClick={() => this.props.onView(item)}
                color="success"
                className="btn-view-crud-list-mobile mr-2"
              />
            }
            { this.renderDeleteBtn(item) }
          </Col>
        </Row>
      );
    };

    // массовые операции
    const checkboxes = (item) => {
      return (
        <Row key={keyGenerator} className="row-ckeckbox">
          <Col xs={12}>
            <Checkbox
              name="bulk-operations"
              checked={!!this.props.store.bulkStore.bulkEditItems.find(el => el === item.id)}
              onChange={() => this.handleCheckBulkOperations(item)}
            />
          </Col>
        </Row>
      );
    };

    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'INVOICE').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="invoice"
        >
          <td className="cell-file" key="file">
            <b>Инвойс</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-invoice'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'invoice')}
              />
              <Label
                for={'load-file-private-invoice'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'PACKING_LIST').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="packing-list"
        >
          <td className="cell-file" key="file">
            <b>Упаковочный лист</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-packing-list'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'packing-list')}
              />
              <Label
                for={'load-file-private-packing-list'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'BILL').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="conosament"
        >
          <td className="cell-file" key="file">
            <b>Коносамент</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-conosament'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'conosament')}
              />
              <Label
                for={'load-file-private-conosament'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'HOME_BILL').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="home-conosament"
        >
          <td className="cell-file" key="file">
            <b>Домашний коносамент</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-home-conosament'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'home-conosament')}
              />
              <Label
                for={'load-file-private-home-conosament'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'FREIGHT_INVOICE').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="freight"
        >
          <td className="cell-file" key="file">
            <b>Счет за фрахт</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-freight'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'freight')}
              />
              <Label
                for={'load-file-private-freight'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }
    if (this.props.isDocs && !this.props.isAllDocs && !items.filter(item => item.type === 'CERTIFICATE').length > 0) {
      body.push(
        <tr
          className="cell-action"
          key="sertificate"
        >
          <td className="cell-file" key="file">
            <b>Сертификат</b>
          </td>
          <td className="cell-created" key="created">
            -
          </td>
          <td className="cell-userName" key="userName">
            -
          </td>
          <td className="cell-action d-flex justify-content-center" key="-">
            <div>
              <input
                ref={(ref) => { this.fileInput = ref; }}
                id={'load-file-private-sertificate'}
                name="file"
                type="file"
                className="d-none"
                onChange={(e) => this.onCreateDoc(e, 'sertificate')}
              />
              <Label
                for={'load-file-private-sertificate'}
              >
                <div className="btn-upload-crud-list" />
              </Label>
            </div>
          </td>
        </tr>
      );
    }

    forEach(items, (item, key) => {
      const rowItems = [];

      forOwn(columns, (data, name) => {
        const value = data.virtual ? item : get(item, name);
        let formattedValue = value;

        if (data.format) {
          // Позволяет избежать багов в реализации format функций.
          formattedValue = value !== undefined ? data.format(value, data, { item, store: this.props.store }) : '-';
        } else if (!formattedValue) {
          formattedValue = '-';
        }

        // Добавление store в пропсы там, где он нужен
        let newFormattedValue = formattedValue;
        if (data.needStore) {
          newFormattedValue = React.cloneElement(
            formattedValue,
            {
              store: this.props.store,
            },
          );
        }

        rowItems.push(
          <Row className={`row-${name}`} key={name}>
            <Col xs={12}>
              <div>
                <span className="label-title">{columnHead[name]}{': '}</span>
                <span className="text">{newFormattedValue}</span>
              </div>
            </Col>
          </Row>
        );
      });

      rowItems.push(actions(item));

      // массовые операции
      if (this.props.bulkOperations) {
        rowItems.unshift(checkboxes(item));
      }

      if (item.valid === false) {
        body.push(
          <Row
            className="crud-list-mobile__inner_card expired"
            key={key}
          >
            <Col xs={12}>{rowItems}</Col>
          </Row>
        );
      } else {
        body.push(
          <Row
            className={`crud-list-mobile__inner_card ${item.expired ? 'expired' : ''}`}
            key={key}
          >
            <Col style={{padding:"15px"}} xs={12}>{rowItems}</Col>
          </Row>);
      }
    });

    return body;
  }

  prepareOrderDocsData = (data) => {
    const docsData = [];

    for (let item of data.filter(x => x)) {
      docsData.push({
        id: item.id,
        doctypeId: item.kind ? item.kind.uid : '',
        documentType: item.kind ? getExistLangValue(item.kind.name ) : '',
        name: item.title,
        creationData: item.created,
        authorName: item.userName,
        fileName: item.file ? item.file.filename : null,
        authorPost: null,
        onDelete: () => this.handleDelete(item),
        onDownload: () => this.downloadDocument(toJS(item)),
        onRename: () => this.props.store.edit(item ? item.id : null, false),
        onSend: () => this.sendDocument(item)
      });
    }

    return docsData;
  };

  getTopControlButtonsForTrackingBlock = () => {
    return (<Fragment>
        <CreateFakeOrderForm onCreate={()=>{}}/>
        <div style={{marginTop:"16px"}}>
          <input
            className="search-bar-mobile"
            onChange={e => this.onSearch(e)}
            onKeyDown={e => this.searchKeyDown(e)}
            value={this.state.searchString}
            placeholder={translate('deliveryOrders.orderList.searchPlaceholder')}
          />
          <FontAwesome name="search" onClick={e => this.searchAction(e)} />
        </div>
    </Fragment>
    )};

  getOrderDocsRow = (items, itemType) => {
    if (items.filter(item => item.type === itemType).length > 0) {
      return null;
    }

    let nameLabel = '';

    switch (itemType) {
      case 'invoice':
        nameLabel = 'Инвойс';
        break;
      case 'packing-list':
        nameLabel = 'Упаковочный лист';
        break;
      case 'conosament':
        nameLabel = 'Коносамент';
        break;
      case 'home-conosament':
        nameLabel = 'Домашний конасмент';
        break;
      case 'freight':
        nameLabel = 'Счет за фрахт';
        break;
      case 'sertificate':
        nameLabel = 'Сертификат';
        break;
    }

    return (
      <tr
        className="order-docs-row"
      >
        <td>
          {nameLabel}
        </td>
        <td>
          <input
            ref={(ref) => { this.fileInput = ref; }}
            id={`load-file-private-${itemType}`}
            name="file"
            type="file"
            className="d-none"
            onChange={(e) => this.onCreateDoc(e, itemType)}
          />
          <Label
            for={`load-file-private-${itemType}`}
          >
            <div className="order-docs-upload">
            </div>
          </Label>
        </td>
      </tr>
    );};


  openChangeOwnerModal = (index) => {
    this.setState({
      currentIndex: index,
      showChangeOwnerOrderModal: true,
    });
  };

  closeChangeOwnerModal = async (isSave) => {
    if (isSave) {
      const { store } = this.props;
      await store.reloadList();
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    } else {
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    }
  };

  onClickNavToOrder=(item)=>{
    if(item && item.order && item.orderCalculation) {
      let role = "";
      if (UserStore.isClient())
        role = "client"
      if (UserStore.isPartner())
        role = "partner"
      if (UserStore.isOperator())
        role = "admin"
      window.location.assign(`/${role}/orders/${item.order.id}#${item.orderCalculation.id}`);
    }
  }

  render() {
    const { store, isTrackingBlock, orderDocs } = this.props;
    const DATE_TIME_FORMAT = 'DD.MM.YYYY';

    if (!this.state.loaded) {
      return <WaitingStatus />;
    }

    if (isTrackingBlock) {
      const items = store.items;
      const lang = getLangInStorage();
      const ruLang = 'ru';

      const trackingBlocks = items.map((item, index) => {
        const cargoDimensionsValue = getCargoSummaryForOrder(item.cargoInfo);
        const cargoInfo = prepareOrderCardCargoInfoData(item.cargoInfo);
        const firstItem = cargoInfo.generals[0] || cargoInfo.containers[0] || cargoInfo.vehicle[0];
        if(!firstItem){
          return;
        }

        const weightUnits = Array.from(new Set(cargoInfo.generals.map(x => x.cargoWeightUnit || x.weightUnit)
          .concat(cargoInfo.containers.map(x => x.cargoWeightUnit || x.weightUnit))
          .concat(cargoInfo.vehicle.map(x => x.cargoWeightUnit || x.weightUnit))
        ));

        const useOneWeightUnit = weightUnits.length === 1;

        const calculateWeight = (arr) => {
          return  arr.reduce((prev, item) => {
            const amount = item.cargoAmount || item.amount || 1;
            const weight = item.cargoWeight || item.weightValue || item.maxWeight;
            const isKg = (item.weightUnit === 'kg' || item.weightUnit === 'кг' ||
            item.cargoWeightUnit === 'kg' || item.cargoWeightUnit === 'кг');

            if (useOneWeightUnit || isKg) {
              return prev + amount * weight;
            }

            return prev + +round((amount * weight / 2.205), 2);
          }, 0);
        };


        const generalsWeight = calculateWeight(cargoInfo.generals);
        const containersWeight = calculateWeight(cargoInfo.containers);
        const vehicleWeight = calculateWeight(cargoInfo.vehicle);

        let cargoTypeName = firstItem.bodyType ? firstItem.bodyType : firstItem.typeName;
        if (!cargoTypeName && firstItem.cargoTypeId === 1) {
          cargoTypeName = translate({ id: 'crud.field.GENERAL_CARGO'});
        }

        const {
          trackingInfo: {
            pointOfDestination: { arrival: { fact: arrivalDateFact } = {} } = {}
          } = {}
        } = item;
        // const statusId = !arrivalDateFact? 1: 2;
        /* временно всем заказам проставлен этот статус */
        const statusId =  item.status;

        const from1stLevel = item.order.fromLocation.city.parent;
        const from2ndLevel = item.order.fromLocation.city.parent.parent;
        const to1stLevel = item.order.toLocation.city.parent;
        const to2ndLevel = item.order.toLocation.city.parent.parent;

        const fromSubTitle = from2ndLevel ? `${lang === ruLang ? from1stLevel.name.ru: from1stLevel.name.en}, ${lang === ruLang ? from2ndLevel.name.ru: from2ndLevel.name.en}`:
          lang === ruLang ? from1stLevel.name.ru: from1stLevel.name.en;

        const toSubTitle = to2ndLevel ? `${lang === ruLang ? to1stLevel.name.ru : to1stLevel.name.en}, ${lang === ruLang ? to2ndLevel.name.ru : to2ndLevel.name.en}` :
          lang === ruLang ? to1stLevel.name.ru: to1stLevel.name.en;

        let fromTitle = lang === ruLang ? item.order.fromLocation.city.name.ru : item.order.fromLocation.city.name.en;
        let commaIndex = fromTitle.indexOf(',');
        if (commaIndex) {
          fromTitle = fromTitle.substr(0, commaIndex);
        }
        let toTitle = lang === ruLang ? item.order.toLocation.city.name.ru: item.order.toLocation.city.name.en;
        commaIndex = toTitle.indexOf(',');
        if (commaIndex) {
          toTitle = toTitle.substr(0, commaIndex);
        }

        const {requestDetails:  {
          companyName: customerCompanyName,
        } = {}} = item;

        const itemData = {
          orderNumber: item.id,
          orderDate: item.createDate,
          price: item.totalCost.value,
          currency: item.totalCost.currency,
          cargoWeight: generalsWeight + containersWeight + vehicleWeight,
          cargoWeightUnit: useOneWeightUnit ? weightUnits[0] : lang !== ruLang ? 'kg' : 'кг',
          cargoDimensionsUnit: firstItem.cargoDimensionsUnit || firstItem.dimensionsUnit,
          cargoTypeId: getCargoTypeIdByCargoInfo(item.cargoInfo),
          cargoTypeName,
          generalTypeId: item.cargoInfo.generals ? item.cargoInfo.generals[0].typeId : 0,
          cargoAmount: cargoDimensionsValue.amount,
          unitName: '',
          cargoDimensionsValue: cargoDimensionsValue.data,
          statusId:statusId,
          statusName: getOrderStatusName(statusId),
          isSubscribed: false,
          fromTitle,
          fromSubtitle: fromSubTitle,
          fromTransportType: 1,
          toTitle,
          toSubtitle: toSubTitle,
          toTransportType: 2,
          customerCompanyName: `${translate({ id: 'orderList.companyName'})}: ${customerCompanyName}`,
        };

        return (
          <Fragment key={item.id}>
            <OrderMobileMiniCard

              {...itemData}
              isShortenInfo={false}
              canEdit={this.state.isLogisticBlockView}
              onEditClick={() => { this.openModal(index); }}
              onPopupChangeOwnerClick={()=>{this.openChangeOwnerModal(index)}}
              onStatusEditClick={this.setStatus}
              onClickToNavOrder={()=>{this.onClickNavToOrder(item)}}
            />
            <ChangeOwnerOrderModal
              id={item.id}
              isShow={this.state.showChangeOwnerOrderModal && this.state.currentIndex === index}
              onClose={this.closeChangeOwnerModal}

            />
          </Fragment>
        );
      });

      return (
        <div className="crud-list">
          {
            <div className="crud-list-title"><pre><h2>{translate('person.delivery')}</h2></pre>
              &nbsp;&nbsp;&nbsp;({store.total})
            </div>
          }

          <div className="crud-list__inner">
            <Row className="align-items-center">
              <Col xs={12} className="order-m-card-mobile-row">
                {this.getTopControlButtonsForTrackingBlock()}
              </Col>
            </Row>
            {trackingBlocks}

            {store.items.length === 0 ? null : this.getPagination()}
          </div>

          {store.items.length === 0 ? <Row className="crud-list__inner_empty"><Col xs={12}>{translate({ id: 'crudList.empty' })}</Col></Row> : ''}
        </div>
      );
    }
    if (orderDocs) {
      return <OrderDocs
      adminMode={this.props.adminMode}
      data={this.prepareOrderDocsData(store.items)}
      onCreateDoc={this.onCreateDoc}
      documentModalClosed={this.state.documentModalClosed}
      trackingInfo = {this.props.trackingInfo}
      contacts={this.props.contacts}/>;
    }

    const cards = this.renderCards(store.items);

    return (
      <div className="crud-list-mobile">
        <div className="crud-list-mobile__inner">
          <Row className="align-items-center">
            <Col xs={12} md={4}>
              {this.getTopControlsButton()}
            </Col>
            <Col xs={12} md={8}>
              {store.items.length === 0 ? null : this.getPagination()}
            </Col>
          </Row>

          <div className="container">
            <Row>
              <Col xs={12}>
                {cards}
              </Col>
            </Row>
          </div>

          {store.items.length === 0 || <hr />}
          {store.items.length === 0 ? null : this.getPagination()}
        </div>

        {store.items.length === 0 ? <Row className="crud-list__inner_empty"><Col xs={12}>{translate({ id: 'crudList.empty' })}</Col></Row> : ''}
      </div>
    );
  }
}

CrudListMobile.defaultProps = {
  isAdd: true,
  editable: true,
  align: 'center',
  isPagination: true,
  crudType: 'list',
  bulkOperations: false,
  downloadCSV: false,
  enableNotifications: true,
  isOnlyDelAction: false,
  isTrackingBlock: false,
  isDocs: false,
  isAllDocs: false,
  orderDocs: false,
  isEdit: true,
  isDelete: true,
};

CrudListMobile.propTypes = {
  store: PropTypes.object,
  title: PropTypes.string,
  isPagination: PropTypes.bool,
  isAdd: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  enableNotifications: PropTypes.bool,
  bulkOperations: PropTypes.bool,
  downloadCSV: PropTypes.bool,
  endpoint: PropTypes.string,
  align: PropTypes.string,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onAdd: PropTypes.func,
  isAllDocs: PropTypes.bool,
  isDocs: PropTypes.bool,
  orderDocs: PropTypes.bool,
  renderActionsButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  isAdminOnlyView: PropTypes.bool,
  isOnlyDelAction: PropTypes.bool,
  crudType: PropTypes.string,
  isTrackingBlock: PropTypes.bool,
  contacts: PropTypes.object,
};

export default injectIntl(CrudListMobile);
