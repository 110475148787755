/* eslint-disable */
import { each, isFunction, isObject } from 'lodash';

import config from '../config';

const BASE_API_VERSION = 'v1';
const BASE_API_VERSION_V2 = 'v2';
/*
* Для удобного использования rest api все url должны быть выполнены в виде функций, принимающие объект с параметрами.
*/

const apiPrefix = ({ api = BASE_API_VERSION }) => `${config.endpointUrl}api/${api}`;
export const webSocketApi = ()=>`${config.endpointUrl}api/v1/messages`
const apiPrefixV2 = ({ api = BASE_API_VERSION_V2 }) => `${config.endpointUrlV2}api/${api}`;

export const API = {
  BASE: (prefix, params = {}) => `${apiPrefix(params)}/${prefix}`,
  //BASE_V2: (prefix, params = {}) => !prefix ? apiPrefixV2(params):`${apiPrefixV2(params)}/${prefix}`,
  APP:{
    MESSAGES_JSON:(params={})=>`${apiPrefix(params)}/common/l10n/messages.json?lang=${params.langCode}`,
    LANGS:(params={})=>`${apiPrefix(params)}/common/l10n/languages`,
  },

  GOOGLE: {
    BASE: 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDqZd6qeFCLCXk23fg0s7SzUFJkEDhDpQA',
    PROXY: (params = {}) => `${apiPrefix(params)}/geo/google?searchString=${params.searchString}&language=${params.language}&country=${params.country}`,
    PROXY_POPULAR: (params = {}) => `${apiPrefix(params)}/geo/google?searchString=${params.searchString}&language=${params.language}&country=${params.country}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,
    PROXY_POPULAR_HUB_FROM: (params = {}) => `${apiPrefix(params)}/geo/google?searchString=${params.searchString}&language=${params.language}&country=${params.country}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true&hubFromId=${params.hubFromId}`,
    PROXY_POPULAR_HUB_TO: (params = {}) => `${apiPrefix(params)}/geo/google?searchString=${params.searchString}&language=${params.language}&country=${params.country}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true&hubToId=${params.hubToId}`,
  },
  PARSERS:{
    BASE: (params={})=>`${apiPrefix(params)}/parser`,
    TRANSLATIONS: (params={})=>`${API.PARSERS.BASE(params)}/translations`
  },
  DICTIONARIES: {
    BASE: (params = {}) => `${apiPrefix(params)}/common/dictionaries`,
    CONTAINER_TYPES: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/cargo/container-types?kind=${params.kind?params.kind:'COMMON'}${params.name ? `&name=${params.name}` : ''}`;
    },

    POPULAR_DESTINATIONS: (params = {})=>`${apiPrefix(params)}/common/dictionaries/popular-destination?countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}`,

    CONTAINER_TYPES_BY_COUNTRY: (params = {}) => `${apiPrefix(params)}/common/dictionaries/container-types/countries${params.countryFromId ? `?countryFromId=${params.countryFromId}` : ''}${params.countryToId ? `${params.countryFromId ? '&' : '?'}countryToId=${params.countryToId}` : ''}`,
    CARGO_CONTAINER_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/cargo/container-types?kind=${params.kind}`,
    DOCUMENT_TYPES: (params = {}) => `${apiPrefix(params)}/documents/kinds`,
    GENERAL_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/cargo/general-types`,
    PALLET_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/cargo/pallet-types`,
    CURRENCIES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/currencies`,
    LANGUAGES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/languages`,
    SEA_LINES: (params = {}) => {
      const query = Object
          .keys(params)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

      return `${API.DICTIONARIES.BASE(params)}/sea-lines?${query}`;
    },
    SEA_PORT_HUBS: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/sea-port-hubs${params.name ? `?name=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`;
    },
    SEA_PORT_HUBS_BY_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/sea-port-hubs/${params.id}`,
    SEA_PORT_HUBS_BY_CITY: (params = {}) => `${API.DICTIONARIES.BASE(params)}/sea-port-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    SEA_PORT_HUBS_BY_CITY_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/sea-port-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,

    SEA_PORT_HUBS_BY_CITY_NAME: (params = {}) => `${API.DICTIONARIES.BASE(params)}/sea-port-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    SEA_PORT_HUBS_BY_CITY_NAME_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/sea-port-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,
    AIR_PORT_HUBS_BY_CITY_NAME: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    AIR_PORT_HUBS_BY_CITY_NAME_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,
    RAILWAY_HUBS_BY_CITY_NAME: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    RAILWAY_HUBS_BY_CITY_NAME_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs/by-name-city-code${params.name ? `?searchString=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,

    SIZE_UNITS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/size-units`,
    WEIGHT_UNITS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/weight-units`,
    SERVICE_CATEGORIES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/service-categories`,
    SERVICE_PROFILES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/service-profiles`,
    SERVICE_LAND: (params = {}) => `${API.DICTIONARIES.BASE(params)}/services/land`,
    PORT_SERVICE_CATEGORIES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/service-categories/port-agent`,
    DICTIONARY_SERVICES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/services?categoryId=${params.id}${params.name ? `&name=${params.name}` : ''}`,
    FREIGHT_SURCHARGES: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/freight-surcharges${params.name ? `?name=${params.name}` : ''}`;
    },
    TRANSPORT_HUBS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/transport-hubs${params.name ? `?name=${params.name}` : ''}`,
    TRANSPORT_HUBS_LIMIT: (params = {}) => `${API.DICTIONARIES.BASE(params)}/transport-hubs-with-limit${params.name ? `?name=${params.name}` : ''}`,
    SEA_PORT_HUBS_LIMIT: (params = {}) => `${API.DICTIONARIES.BASE(params)}/sea-port-hubs-with-limit${params.name ? `?name=${params.name}` : ''}`,
    AIRPORT_HUBS_LIMIT: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs-with-limit${params.name ? `?name=${params.name}` : ''}`,
    RAILWAY_HUBS_LIMIT: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs-with-limit${params.name ? `?name=${params.name}` : ''}`,
    TRANSPORT_HUBS_BY_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/transport-hubs/${params.id}`,
    ALL_HUBS: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/all-hubs${params.name ? `?name=${params.name}` : ''}`;
    },
    ALL_HUBS_WITH_CHINA: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/all-hubs${params.name ? `?name=${params.name}&onlyRussianTransportHubs=false` : ''}`;
    },
    ALL_HUBS_Russia: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/all-hubs/${params.name ? `?name=${params.name}&onlyRussianTransportHubs=true` : ''}`;
    },
    ALL_HUBS_ID: (params = {}) => `${API.DICTIONARIES.BASE(params)}/all-hubs/${params.id}`,
    AIR_HUBS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs${params.name ? `?name=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    ALL_HUBS_BY_CITY_ID: (params = {}) => `${API.DICTIONARIES.BASE(params)}/all-hubs/by-city-id?cityId=${params.cityId ? params.cityId : ''}`,
    AIR_HUBS_BY_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/airport-hubs/${params.id}`,
    AIR_HUBS_HAS_NO_TERMINALS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs/has-no-terminals${params.name ? `?name=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    ALL_HUBS_BY_ADDRESS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/all-hubs/by-address`,
    AIR_PORT_HUBS_BY_CITY: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    AIR_PORT_HUBS_BY_CITY_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/airport-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,
    AVIA_COMPANIES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/avia-companies`,
    AIRPORT_SERVICES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/services/airport`,
    CUSTOMS_CATEGORY: (params = {}) => `${apiPrefix(params)}/dictionaries/customs-category/all-categories/by-query${params.query ? `?query=${params.query}` : ''}`,
    RAILWAY_HUBS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs${params.name ? `?name=${params.name}` : ''}`,
    RAILWAY_HUBS_BY_ID: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs/${params.id}`,
    RAILWAY_HUBS_BY_CITY: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}`,
    RAILWAY_HUBS_BY_CITY_POPULAR: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-hubs/by-city${params.name ? `?cityName=${params.name}` : ''}${params.countryId ? `${params.name ? '&' : '?'}countryId=${params.countryId}` : ''}&countryFromId=${params.countryFrom}&countryToId=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&isFrom=${params.isFrom}&isPopular=true`,
    RAILWAY_SERVICE_CATEGORIES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/service-categories/station`,
    CUSTOMS_CATEGORIES: (params = {}) => `${apiPrefix(params)}/dictionaries/customs-category`,
    CUSTOMS_UPDATE:(params={})=>`${apiPrefix(params)}/customs/update`,
    CUSTOMS_CATEGORY_BY_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/customs-category/${params.id}`,
    AUDIT_EVENT_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/audit/event-types${params.name ? `?name=${params.name}` : ''}`,
    AUDIT_OBJECT_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/audit/object-types${params.name ? `?name=${params.name}` : ''}`,
    TRANSPORTATION_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/transportation-types${params.name ? `?name=${params.name}` : ''}`,
    TRANSPORTATION_CARGO_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/transportation-cargo-all-types${params.name ? `?name=${params.name}` : ''}`,
    FORWARDERS: (params = {}) => `${apiPrefix(params)}/dictionaries/forwarder`,
    INTEREST_TYPES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/interest-types${params.name ? `?name=${params.name}` : ''}`,
    INTEREST_TYPES_BY_ID: (params = {}) => `${API.DICTIONARIES.BASE(params)}/interest-types/${params.id}`,
    RAILWAY_OPERATORS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/railway-operators`,
    EVENTS: (params = {}) => `${API.DICTIONARIES.BASE(params)}/events-cargo`,
    STATUSES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/statuses-cargo`,
    TRANSPORTATION_RULES: (params = {}) => `${API.DICTIONARIES.BASE(params)}/transportation-rules`,
    ORDER_LOCATIONS:(params = {}) => `${API.DICTIONARIES.BASE(params)}/order-locations${params.name ? `?name=${params.name}` : ''}`,
    SEND_TRANSPORTATION_RULE: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/transportation-rules`;
    },
    DELETE_TRANSPORTATION_RULE: (params = {}) => {
      return `${API.DICTIONARIES.BASE(params)}/transportation-rules/${params.id}`;
    },
    GET_ORDER_TEMPLATES: (params={})=>{
      return `${apiPrefix(params)}/dictionaries/templates-order/all?name=${params.name?params.name:''}`;
    },
    VEHICLE_TYPES:(params={})=>`${API.DICTIONARIES.BASE(params)}/vehicle-types`,
  },
  FREIGHT_CONTAINER: {
    BASE: (params = {}) => `${apiPrefix(params)}/surcharge-value/sea-freight/container/active${params.name ? `?code=${params.code}` : ''}`,
    BASE_ALL: (params = {}) => `${apiPrefix(params)}/surcharge-value/sea-freight/container/active/all`,
    FREIGHT_SURCHARGES_BY_SEA_LINE: (params = {}) => `${API.FREIGHT_CONTAINER.BASE(params)}?seaLineId=${params.id}${params.code ? `&code=${params.code}` : ''}`,
    FREIGHT_SURCHARGES_BY_TARIFF: (params = {}) => `${API.FREIGHT_CONTAINER.BASE(params)}/tariff?seaLineId=${params.id}&seaTariffId=${params.seaTariffId}${params.code ? `&code=${params.code}` : ''}`,
  },
  FREIGHT_GENERAL: {
    BASE: (params = {}) => `${apiPrefix(params)}/surcharge-value/sea-freight/general/active${params.name ? `?code=${params.code}` : ''}`,
    FREIGHT_SURCHARGES_BY_SEA_LINE: (params = {}) => `${API.FREIGHT_GENERAL.BASE(params)}?seaLineId=${params.id}${params.code ? `&code=${params.code}` : ''}`,
    FREIGHT_SURCHARGES_BY_TARIFF: (params = {}) => `${API.FREIGHT_GENERAL.BASE(params)}/tariff?seaLineId=${params.id}&seaTariffId=${params.seaTariffId}${params.code ? `&code=${params.code}` : ''}`,
  },
  FILTERS: {
    BASE: (params = {}) => `${apiPrefix(params)}/filters`,
    FILTERS: (params = {}) => API.FILTERS.BASE(params),
    FILTERS_BY_CONTEXT: (params = {}) => `${API.FILTERS.BASE(params)}/context/${params.context}`,
  },
  COLLECTION_VIEWS: {
    BASE: (params = {}) => `${apiPrefix(params)}/collection-views`,
    BY_CONTEXT: (params = {}) => `${API.COLLECTION_VIEWS.BASE(params)}/context/${params.context}`,
  },
  CRUD: {
    LANGUAGES: (params = {}) => `${apiPrefix(params)}/dictionaries/languages`,
    CURRENCIES: (params = {}) => `${apiPrefix(params)}/dictionaries/currencies`,
    CONTAINER_TYPES: (params = {}) => `${apiPrefix(params)}/dictionaries/container-types`,
  },
  STATISTICS:{
    BASE: (params={})=>`${apiPrefix(params)}/statistics`,
    POPULAR_DESTINATIONS: (params={})=>`${API.STATISTICS.BASE}/get-popular-destinations?countryFrom=${params.countryFrom}&countryTo=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&limit=${params.limit}&orgId=${params.orgId}`,
    POPULAR_DESTINATIONS_TREND: (params={})=>`${API.STATISTICS.BASE}/get-trending?countryFrom=${params.countryFrom}&countryTo=${params.countryTo}&typeFrom=${params.typeFrom}&typeTo=${params.typeTo}&limit=${params.limit}&orgId=${params.orgId}`,
    POPULAR_COUNTRY: (params={})=>`${API.STATISTICS.BASE}/get-popular-countries?limit=${params.limit}`,
    GET_INFO_SETTINGS: (params={})=>`${API.STATISTICS.BASE}/get-info-scan?type=${params.type}`,
    UPDATE_INFO_SETTINGS: (params={})=>`${API.STATISTICS.BASE}/update-info-scan?type=${params.type}`,
    GET_INFO_WORK_JOB: (params={})=>`${API.STATISTICS.BASE}/get-status-job?type=${params.type}`,
  },
  TEMPLATES:{
    BASE: (params={})=>`${apiPrefix(params)}/templates-documents`,
    GET_ALL: (params={})=>`${API.TEMPLATES.BASE}/all`,
    GENERATE_DOCX: (params={})=>`${API.TEMPLATES.BASE}/get-offer/${params.order}/${params.calculation}/${params.lang}/docx`,
    GENERATE_PDF: (params={})=>`${API.TEMPLATES.BASE}/get-offer/${params.order}/${params.calculation}/${params.lang}/pdf`,
    GENERATE_TEST_OFFER: (params={})=>`${API.TEMPLATES.BASE}/offer-test/${params.type}/${params.lang}`,
    DOWNLOAD_TEMPLATE: (params={})=>`${API.TEMPLATES.BASE}/download/${params.type}/${params.lang}`,
    DOWNLOAD_DEFAULT_TEMPLATE: (params={})=>`${API.TEMPLATES.BASE}/download/default/${params.type}/${params.lang}`,
    DELETE_TEMPLATE: (params={})=>`${API.TEMPLATES.BASE}/delete`,

  },
  STATIC_FILE_SERVER:{
    BASE: (params={})=>`${apiPrefix(params)}/static-files`,
    GET_ALL: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/all`,
    DOWNLOAD_FILE: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/download/${params.organizationId}/${params.type}`,
    DOWNLOAD_FILE_BY_DOMAIN: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/download-by-domain/${params.domain}/${params.type}`,
    DELETE_FILE: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/delete`,
    CHECK_EXIST_LOGO: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/check-exist-file/${params.type}`,
    CHECK_EXIST_LOGO_BY_DOMAIN: (params={})=>`${API.STATIC_FILE_SERVER.BASE}/check-exist-file-by-domain/${params.domain}/${params.type}`,

  },
  TEMPLATES_DESCRIPTION_TARIFFS:{
    BASE: (params={})=>`${apiPrefix(params)}/templates/description-tariffs`,
    ALL: (params={})=>`${API.TEMPLATES_DESCRIPTION_TARIFFS.BASE(params)}/all`,
    CREATE: (params={})=>`${API.TEMPLATES_DESCRIPTION_TARIFFS.BASE(params)}`,
  },
  REPORTS: {
    BASE: (params = {}) => `${apiPrefix(params)}/reports`,
    REPORTS: (params = {}) => `${API.REPORTS.BASE(params)}?kind=${params.kind}${params.dateFrom ? `&from=${params.dateFrom}` : ''}${params.dateTo ? `&to=${params.dateTo}` : ''}&orgId=${params.orgId}`,
    REMOVE: (params = {}) => `${API.REPORTS.BASE(params)}?orgId=${params.orgId}`,
    DOWNLOAD_CSV: (params = {}) => {
      return `${API.REPORTS.BASE(params)}/download-csv?kind=${params.kind}&encoding=UTF-8&separator=%3B&quote=%22${params.dateFrom ? `&from=${params.dateFrom}` : ''}${params.dateTo ? `&to=${params.dateTo}` : ''}&orgId=${params.orgId}`;
    },
  },
  GEO: {
    BASE: (params = {}) => `${apiPrefix(params)}/geo`,
    GET_GEO_OBJECT_BY_PARENT_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/geo?parentId=${params.id}`,
    GET_GEO_OBJECT_BY_ID: (params = {}) => `${apiPrefix(params)}/dictionaries/geo/${params.id}`,
    COUNTRIES: (params = {}) => `${API.GEO.BASE(params)}/countries${params.name ? `?name=${params.name}` : ''}`,
    CITIES: (params = {}) => `${API.GEO.BASE(params)}/countries/${params.code}/cities${params.name ? `?name=${params.name}` : ''}`,
    CITIES_EXTENDED: (params = {}) => `${API.GEO.BASE(params)}/countries/${params.code}/cities-extended${params.name ? `?name=${params.name}` : ''}`,
    REGIONS: (params = {}) => `${API.GEO.BASE(params)}/countries/${params.code}/regions${params.name ? `?name=${params.name}` : ''}`,
    ALL_REGIONS: (params = {}) => `${API.GEO.BASE(params)}/regions${params.name ? `?name=${params.name}` : ''}`,
    ALL_CITIES: (params = {}) => `${API.GEO.BASE(params)}/cities${params.name ? `?name=${params.name}` : ''}`,
    REGIONS_WORLD: (params = {}) => `${apiPrefix(params)}/dictionaries/world-regions`,
    COUNTRIES_BY_REGIONS_WORLD: (params = {}) => `${apiPrefix(params)}/dictionaries/world-regions/countries/${params.id}`,
    DISPUTED_TERRITORIES: (params = {}) => `${API.GEO.BASE(params)}/disputed-territories`,
  },
  DOCUMENTS: {
    DOWNLOAD_FILE: (params = {}) => `${apiPrefix(params)}/documents/get/${params.item.id}/${params.item.file.id}`,
    GENERATE_AND_DOWNLOAD_FILE: (params = {}) => `${apiPrefix(params)}/documents/generate/${params.item.orderDeliveryRequestGroup}`,
    UPLOAD_FILE: (params = {}) => `${apiPrefix(params)}/documents/update/${params.item.id}/file`,
  },
  SERVICES: {
    BASE: (params = {}) => `${apiPrefix(params)}/dictionaries`,
    SERVICES: (params = {}) => `${API.SERVICES.BASE(params)}/services`,
    CATEGORIES: (params = {}) => `${API.SERVICES.BASE(params)}/service-categories`,
    PROFILES: (params = {}) => `${API.SERVICES.BASE(params)}/service-profiles`,
    ALL_ORGANIZATION_PROFILES: (params = {}) => {
      return `${API.SERVICES.BASE(params)}/service-profiles`;
    },
  },
  CUSTOMS: {
    BASE: (params = {}) => `${apiPrefix(params)}/customs`,
  },
  SEO: {
    GET_CITIES_DATA: (params = {}) => `${apiPrefix(params)}/seo/find-cities`,
    GET_NEAR_POINTS: (params = {}) => {
      return `${apiPrefix(params)}/seo/find-cities/near/${params}?limit=50`;
    },
  },
  DISCLAIMERS:{
    BASE: (params = {}) => `${apiPrefix(params)}/Disclaimer/disclaimer/order`,
    CURRENT_CALCULATION:(params={})=> `${API.DISCLAIMERS.BASE}/${params.idOrder}/calculation/${params.idCalculation}/${params.lang}`
  },
  ORDERS: {
    BASE: (params = {}) => `${apiPrefix(params)}/orders`,
    //BASE_V2: (params = {}) => `${apiPrefixV2(params)}/orders`,
    CURRENT_ORDER: (params = {}) => `${API.ORDERS.BASE}/${params.id}${`?onlySaved=${params.onlySaved}`}`,
    CURRENT_ORDER_TASKS: (params = {}) => `${API.ORDERS.BASE}/${params.id}/tasks`,
    CURRENT_ORDER_EXTERNAL_RATES: (params = {}) => `${API.ORDERS.BASE}/${params.id}/external-rates?order=${params.sortType}`,
    ORDER_GENERAL: (params = {}) => `${API.ORDERS.BASE}/${params.id}/general`,
    ORDER_CONTAINER: (params = {}) => `${API.ORDERS.BASE}/${params.id}/container${params.type}`,
    CALCULATIONS: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations${`?onlySaved=${params.onlySaved}&isWidget=${params.isWidget}&calculationId=${params.hash}`}`,
    CALCULATIONS_EMAIL_SEND: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations/bulk/send`,
    QUESTION_BY_CALCULATION_EMAIL_SEND: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations/${params.calcId}/refine-price`,
    QUESTION_BY_ORDER_EMAIL_SEND: (params = {}) => `${API.ORDERS.BASE}/${params.id}/refine-price`,
    REQUEST_DELIVERY: (params = {}) => {
      return `${API.ORDERS.BASE}/${params.id}/calculations/${params.calcId}/request-delivery/${params.type}`;
    },
    UPDATE_CALCULATION: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations/${params.calcId}`,
    UPDATE_PRICE_CALCULATION: (params = {}) => `${API.ORDERS.BASE}/${params.orderId}/update-calculation/${params.calculationId}`,
    EXPIRED_CALCULATIONS: (params = {}) => `${API.ORDERS.BASE}/expired-order/${params.orderId}`,
    POST_UPDATE_EXPIRED_ORDER: (params={})=>`${API.ORDERS.BASE}/expired-order/post-update/${params.orderId}`,
    SORT_CALCULATIONS: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations${`?onlySaved=${params.onlySaved}`}`,
    ORDER_DOCX: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations/${params.calcId}/docx`,
    ORDER_PDF: (params = {}) => `${API.ORDERS.BASE}/${params.id}/calculations/${params.calcId}/pdf`,
    CALC_CUSTOMS: (params = {}) => `${API.ORDERS.BASE}/${params.id}/customs`,
    UPDATE_CUSTOMS: (params = {}) => `${API.ORDERS.BASE}/${params.id}/customs/${params.customsId}`,
    GET_CUSTOMS: (params = {}) => `${API.ORDERS.BASE}/customs?orderId=${params.id}`,

    INSURANCE: () => `${API.ORDERS.BASE}/insurances`,
    CUSTOMS: () => `${API.ORDERS.BASE}/customs`,
    EXPIRED_ORDERS_COUNT: ()=>`${API.ORDERS.BASE}/count-expired-orders`,
    EXPIRED_ORDERS: ()=>`${API.ORDERS.BASE}/expired-orders`,
    CREATE_FAKE:()=>`${API.ORDERS.BASE}/create/fake`,
    CHECK_ACCESS(param) {
      return `${API.ORDERS.BASE}/${param.id}/check-access`;
    },
    RECALCULATE_FAKE_PRICES: (params = {}) => `${API.ORDERS.BASE}/calculate-fake-price`,
    GET_ALLOWED_SERVICES_FAKE_ORDER: (params = {}) => `${API.ORDERS.BASE}/get-allowed-services`,
    EXPORT_EMPTY_XLSX:()=>`${API.ORDERS.BASE}/empty/export/xlsx?context=emptyOrdersFilters`,
  },
  ORDER_DELIVERY: {
    BASE: (params = {}) => `${apiPrefix(params)}/order-delivery`,
    REQUEST_GROUP: (params = {}) => `${apiPrefix(params)}/order-delivery/request-groups/${params.id}`,
    REQUESTS: (params = {}) => `${apiPrefix(params)}/order-delivery/requests/${params.id}`,
    UPDATE_REQUEST_STATUS: (params = {}) => {
      const { status, id } = params;
      return `${apiPrefix(params)}/order-delivery/requests/${id}/status?status=${status}`;
    },
    UPDATE_REQUEST_GROUP_STATUS: (params = {}) => {
      const { status, id } = params;
      return `${apiPrefix(params)}/order-delivery/request-groups/${id}/status?status=${status}`;
    },
    UPDATE_CREATE_DATE: (params = {}) => {
      return `${apiPrefix(params)}/order-delivery/request-groups/${params.id}/change-create-date`
    },
    GET_ORDER_TEMPLATES: (params = {}) => `${apiPrefix(params)}/order-delivery/request-groups/order-details-values`,
    DOWNLOAD_REQUEST_GROUP: (params = {}) => `${apiPrefix(params)}/order-delivery/request-groups/${params.id}/docx`,
    GET_TRACKING: (params = {}) => `${API.ORDER_DELIVERY.REQUEST_GROUP(params)}/logistics-details`,
    TRACKING: (params = {}) => `${API.ORDER_DELIVERY.REQUEST_GROUP(params)}/logisticians-popup`,
    SEND_STATUS: (params = {}) => {
      const { id, idCargo,status } = params;
      return `${apiPrefix(params)}/order-delivery/request-groups/${id}/tracking/${idCargo}/manual-status?statusName=${status}`;
    },
    SET_STATUS: (params = {}) => {
      const { id,status} = params;
      return `${apiPrefix(params)}/order-delivery/request-groups/${id}/status?status=${status}`;
    },
    SEND_LOGISTIC_BLOCK: (params = {}) => `${API.ORDER_DELIVERY.REQUEST_GROUP(params)}/tracking-info/${params.idCargo}?sync=${params.sync}`,
    MANUAL_COST: (params = {}) => `${API.ORDER_DELIVERY.REQUEST_GROUP(params)}/manualCost`,
    SEND_DESCRIPTION: (params = {}) => {
      const { id, extId } = params;
      return `${apiPrefix(params)}/order-delivery/request-groups/${id}/extId?extId=${extId}`;
    },
    UPDATE_REQUEST_DETAILS: (params = {}) => {
      return `${apiPrefix(params)}/order-delivery/request-groups/${params}/requestDetails`;
    },
    RECALCULATE_TRACKING_DATE: (params={})=>{
      return `${apiPrefix(params)}/order-delivery/request-groups/${params.id}/recalculate-tracking-date/${params.idCargo}?sync=${params.sync}`
    },
    CHECK_CHANGE_OWNER:(params={})=>{
      return `${apiPrefix(params)}/order-delivery/request-groups/check-change-owner/${params.id}`
    },
    CHANGE_OWNER:(params={})=>`${apiPrefix(params)}/order-delivery/request-groups/change-owner`,
    UPDATE_FAKE_PRICES: (params={})=>`${apiPrefix(params)}/order-delivery/request-groups/${params.id}/update-fake-services`,
    TRACKING_INFO: (params={})=>`${apiPrefix(params)}/order-delivery/request-groups/${params.id}/tracking-info`,
    MIN_STATUS_AND_TRACKING_INFO: (params={})=>`${apiPrefix(params)}/order-delivery/request-groups/${params.id}/min-status-and-tracking-info`,
  },
  NOTIFICATIONS: {
    BASE: (params = {}) => `${apiPrefix(params)}/common/notifications`,
    SEND_QUESTION: (params = {}) => `${API.NOTIFICATIONS.BASE(params)}/send-question`,
    OLD_NOTIFICATIONS: (params={})=>`${apiPrefix(params)}/common/notifications/old?start=${params.start}`,
    UPDATE_STATUS_NOTIFICATION: (params={})=>`${apiPrefix(params)}/common/notifications/change-notification`
  },
  CLIENTS: {
    BASE: (params = {}) => `${apiPrefix(params)}/auth`,
    //CHANGE_PASSWORD: (params = {}) => `${API.CLIENTS.BASE(params.api)}/${params.id}/change-password`,
    CHANGE_PASSWORD: (params = {}) => `${apiPrefix(params)}/users/current/change-password`,
    REGISTER: (params = {}) => `${API.CLIENTS.BASE(params)}/register`,
    LOGIN: (params = {}) => `${API.CLIENTS.BASE(params)}/login`,
    CONFIRM_CODE: (params = {}) => `${API.CLIENTS.BASE(params)}/${params.id}/confirm`,
    REGENERATE_CODE: (params = {}) => `${API.CLIENTS.BASE(params)}/${params.id}/regenerate-code`,
    SAVE_PERSONAL_INFO: (params = {}) => `${API.CLIENTS.BASE(params)}/${params.id}`,
    WITH_ORGANIZATION: (params = {}) => `${API.CLIENTS.BASE(params)}/with-organization`,
    REFRESH_TOKEN(params = {}) {
      return `${API.CLIENTS.BASE(params)}/login/refreshToken`;
    },
  },
  PARTNERS: {
    BASE: (params = {}) => `${apiPrefix(params)}/users/partners`,
    CHANGE_PASSWORD: (params = {}) => `${API.PARTNERS.BASE(params)}/${params.id}/change-password`,
    REGISTER: (params = {}) => `${API.PARTNERS.BASE(params)}/register`,
    CONFIRM_CODE: (params = {}) => `${API.PARTNERS.BASE(params)}/${params.id}/confirm`,
    REGENERATE_CODE: (params = {}) => `${API.PARTNERS.BASE(params)}/${params.id}/regenerate-code`,
  },
  USERS: {
    BASE: (params = {}) => `${apiPrefix(params)}/users`,
    BASE_EMAIL: (params = {}) => `${apiPrefix(params)}/users/all${params.name?'?email='+params.name:''}`,
    CHANGE_PASSWORD: (params = {}) => `${API.USERS.BASE(params)}/current/change-password`,
    UPDATE: (params = {}) => `${API.USERS.BASE(params)}/${params.id}`,
    SEND_RESTORE_PASSWORD: (params = {}) => `${API.USERS.BASE(params)}/send-restore-password`,
    RESTORE_PASSWORD: (params = {}) => `${API.USERS.BASE(params)}/restore-password`,
    SUBORDINATE_ROLES: (params = {}) => `${API.USERS.BASE(params)}/subordinate-roles`,
    BY_ORGANIZATION:(params={})=>`${API.USERS.BASE(params)}/by-organization/${params.id}`,
    CHECK_ACCESS_WIZARD(params={}) {
      return `${API.USERS.BASE(params)}/check-access-wizard?domain=${params.domain}`;
    },
    CHECK_TOKEN: (params = {}) => `${API.USERS.BASE(params)}/check-token`,
    COUNT_CLIENTS:(params={})=>`${API.USERS.BASE(params)}/count`,
  },
  LANGUAGES: {
    BASE: (params = {}) => `${apiPrefix(params)}/dictionaries/languages`,
    LANGUAGE: (params = {}) => `${API.LANGUAGES.BASE(params)}/${params.id}`,
    UPDATE_TRANSLATIONS: (params = {}) => `${API.LANGUAGES.BASE(params)}/${params.id}/translations`,
  },
  CURRENCIES: {
    BASE: (params = {}) => `${apiPrefix(params)}/dictionaries/currencies`,
    CURRENCY: (params = {}) => `${API.CURRENCIES.BASE(params)}/${params.id}`,
    UPDATE_TRANSLATIONS: (params = {}) => `${API.CURRENCIES.BASE(params)}/${params.id}/translations`,
  },
  ORGANIZATION: {
    BASE: (params = {}) => `${apiPrefix(params)}/organizations${params.name ? `?name=${params.name}` : ''}`,
    BASE_ALL: (params = {}) => `${API.ORGANIZATION.BASE(params)}/all`,
    CLIENTS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/clients`,
    ORGANIZATION: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}`,
    BULK_DELETE: (params = {}) => `${API.ORGANIZATION.BASE(params)}/bulk/delete`,
    GET_PROFILES: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/profiles`,
    PROFILES: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/profiles`,
    PROFILE: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/profiles/${params.profileId}`,
    APPROVE_PROFILE: (params = {}) => `${API.ORGANIZATION.PROFILE(params)}/approve`,
    REJECT_PROFILE: (params = {}) => `${API.ORGANIZATION.PROFILE(params)}/reject`,
    UPDATE_PROFILES:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/update-profiles`,
    GET_EXPIRED_PORT_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-port-tariffs-count`,
    GET_EXPIRED_ROAD_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-road-tariffs-count`,
    GET_EXPIRED_SEA_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-sea-tariffs-count`,
    GET_EXPIRED_SURCHARGES_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-surcharges-count`,
    GET_EXPIRED_AIR_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-air-tariffs-count`,
    GET_EXPIRED_AIRPORT_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-airport-tariffs-count`,
    GET_EXPIRED_RAILWAY_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-railway-tariffs-count`,
    GET_EXPIRED_RAILWAY_STATION_TARIFFS: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-railway-station-tariffs-count`,
    //GET_EXPIRED_COMPLEX_TARIFF: (params = {}) => `${API.ORGANIZATION.BASE(params)}/${params.id}/expired-complex-tariffs-count`,
    GET_ALL_EXTERNAL:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/get-status-external-api`,
    GET_ALL_EXTERNAL_AGORA:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/get-status-external-api-agora`,
    CHANGE_STATUS_API:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/status-external-api`,
    CHANGE_STATUS_API_AGORA:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/status-external-api-agora`,
    GET_PARENT_DOMAIN:(params={})=>`${API.ORGANIZATION.BASE(params)}/get-parent-domain/${params.id}`,
    CHANGE_TYPES_AGORA_API_INTERNAL_TARIFFS:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/change-types-agora-api-internal-tariffs`,
    CHANGE_STATUS_AGORA_API:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/enable-agora-api?enable=${params.enable}`,
    BANK_DETAILS:{
      BASE:(params={})=>`${API.ORGANIZATION.BASE(params)}/bank-details`,
      UPDATE:(params={})=>`${API.ORGANIZATION.BANK_DETAILS.BASE(params)}/${params.id}`,
      CREATE:(params={})=>`${API.ORGANIZATION.BANK_DETAILS.BASE(params)}`,
      GET_BY_ORGANIZATION:(params={})=>`${API.ORGANIZATION.BANK_DETAILS.BASE(params)}/by-organization/${params.id}`
    },
    ACT_DETAILS:{
      BASE:(params={})=>`${API.ORGANIZATION.BASE(params)}/acts`,
      CREATE:(params={})=>`${API.ORGANIZATION.ACT_DETAILS.BASE(params)}/create`,
      UPDATE:(params={})=>`${API.ORGANIZATION.ACT_DETAILS.BASE(params)}/${params.id}/update`,
      DOWNLOAD:(params={})=>`${API.ORGANIZATION.ACT_DETAILS.BASE(params)}/download/${params.id}/${params.idFile}`
    },
    CHECK_DOMAIN:(params={}) =>`${API.ORGANIZATION.BASE(params)}/check-domain/${params.domain}`,
    CHANGE_GLOBAL_SETTINGS_API:(params={})=>`${API.ORGANIZATION.BASE(params)}/${params.id}/set-global-settings-external-api?enable=${params.global}&agora=${params.isAgora}`,
    UPDATE_FULL(params={}) {return `${API.ORGANIZATION.BASE(params)}/${params.id}/update-full`;},
    SETTINGS_API(params={}) {return `${API.ORGANIZATION.BASE(params)}/${params.id}/settings-api`;},
    GET_COUNTRY_CODES_WIZARD: (params = {}) => `${API.ORGANIZATION.BASE(params)}/getCountryCodesWizard/${params.domain}`,
  },
  ORGANIZATION_EMAIL_SETTINGS: {
    BASE:  (params = {}) => `${apiPrefix(params)}/email-settings`,
    SAVE_CHANGES:(params={})=>`${API.ORGANIZATION_EMAIL_SETTINGS.BASE(params)}/save-changes`,
    SEND_TEST_EMAIL:(params={})=>`${API.ORGANIZATION_EMAIL_SETTINGS.BASE(params)}/send-test-email?email=${params.email}`,
    GENERATE_TEST_EMAIL:(params={})=>`${API.ORGANIZATION_EMAIL_SETTINGS.BASE(params)}/generate-test-email?email=${params.email}`,
  },
  COMMISSION: {
    BASE: (params = {}) => `${apiPrefix(params)}/comissions`,
    COMMISSION: (params = {}) => `${API.COMMISSION.BASE(params)}?profileId=${params.id}&cargoType=${params.cargoType}`,
    CREATE_COMMISSION: (params = {}) => `${API.COMMISSION.BASE(params)}`,
    UPDATE_COMMISSION: (params = {}) => `${API.COMMISSION.BASE(params)}/${params.id}`,
    COMMISSION_INTEGRATOR: (params = {}) => `${API.COMMISSION.BASE(params)}/integrator?cargoType=${params.cargoType}`,
    CREATE_COMMISSION_INTEGRATOR: (params = {}) => `${API.COMMISSION.BASE(params)}/integrator`,
    UPDATE_COMMISSION_INTEGRATOR: (params = {}) => `${API.COMMISSION.BASE(params)}/integrator/${params.id}`,
  },
  TRUNKING: {
    BASE: (params = {}) => `${apiPrefix(params)}/tariff/vehicle`,
    UPLOAD_TRUNKING: (params = {}) => `${API.TRUNKING.BASE(params)}/uploading`,
    DOWNLOAD_TRUNKING: (params = {}) => `${API.TRUNKING.BASE(params)}/file`,
    DOWNLOAD_TRUNKING_ID: (params = {}) => `${API.TRUNKING.BASE(params)}/file/${params.id}`,
    VEHICLE_FILE_EXIST: (params = {}) => `${API.TRUNKING.BASE(params)}/file/exist`,
    LAST_FILES: (params={})=>`${API.TRUNKING.BASE(params)}/files/last?fileSize=${params.size}`,
    PLATO: (params = {}) => `${API.TRUNKING.BASE(params)}/platon`,
    PRIVATE: (params = {}) => `${API.TRUNKING.BASE(params)}/private`,
    DOWNLOAD_PRIVATE: (params = {}) => `${API.TRUNKING.BASE(params)}/private/${params.id}/file`,
    DELETE_ALL: (params = {}) => `${API.TRUNKING.BASE(params)}/delete-all`,
  },
  TRUNKING_INTERNATIONAL: {
    BASE: (params = {}) => `${apiPrefix(params)}/tariff/vehicle-international`,
    UPLOAD_TRUNKING: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/uploading`,
    DOWNLOAD_TRUNKING: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/file`,
    VEHICLE_FILE_EXIST: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/file/exist`,
    LAST_FILES: (params={})=>`${API.TRUNKING_INTERNATIONAL.BASE(params)}/files/last?fileSize=${params.size}`,
    DOWNLOAD_TRUNKING_ID: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/file/${params.id}`,
    PLATO: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/platon`,
    PRIVATE: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/private`,
    DOWNLOAD_PRIVATE: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/private/${params.id}/file`,
    DELETE_ALL: (params = {}) => `${API.TRUNKING_INTERNATIONAL.BASE(params)}/delete-all`,
  },
  PALLET: {
    BASE: (params = {}) => `${apiPrefix(params)}/tariff/pallet`,
    UPLOAD: (params = {}) => `${API.PALLET.BASE(params)}/uploading`,
    DOWNLOAD: (params = {}) => `${API.PALLET.BASE(params)}/${params.id}/file`,
  },
  EXTERNAL_API: {
    BASE: (params = {}) => `${apiPrefix(params)}/dictionaries/external-apis`,
    ALL: (params = {}) => `${apiPrefix(params)}/dictionaries/external-apis/all`,
    SET_ENABLED: (id, params = {}) => `${API.EXTERNAL_API.BASE(params)}/${id}/set-enabled`,
    RESET_USAGE_LIMIT:(params={})=>`${API.EXTERNAL_API.BASE(params)}/${params.id}/reset-usage-limit`
  },
  EXTERNAL_API_AGORA: {
    BASE: (params = {}) => `${apiPrefix(params)}/external-api/agora`,
    SET_ENABLED: (id, params = {}) => `${API.EXTERNAL_API_AGORA.BASE(params)}/${id}/set-enabled`,
    RESET_USAGE_LIMIT:(params={})=>`${API.EXTERNAL_API_AGORA.BASE(params)}/${params.id}/reset-usage-limit`
  },
  WP_API: {
    BASE: siteUrl => `${siteUrl}/wp-json/wp/v2`,
    AGREEMENT: (siteUrl, pageId) => `${API.WP_API.BASE(siteUrl)}/pages/${pageId}`,
  },
  CDEK: {
    BASE: (params = {}) => `${apiPrefix(params)}/partners/cdek`,
    CREATE_ORDER: (id, tariffCode, params = {}) => `${API.CDEK.BASE(params)}/order/${id}/${tariffCode}`,
    GET_ORDER: (id, tariffCode, params = {}) => `${API.CDEK.BASE(params)}/order/${id}/${tariffCode}`,
    UPDATE_ORDER: (id, tariffCode, params = {}) => `${API.CDEK.BASE(params)}/order/${id}/${tariffCode}`,
    UPDATE_ORDER_STATUS: (uuid, params = {}) => `${API.CDEK.BASE(params)}/order/${uuid}`,
    INTAKE_COURIER: (uuid, params = {}) => `${API.CDEK.BASE(params)}/courier/intake`,
    GET_EVENTS_BY_UUID:(params={})=>`${API.CDEK.BASE(params)}/events/${params.uuid}`
  },


  SUBSCRIPTION:{
    BASE:(params={})=>`${apiPrefix(params)}/subscription`,
    CONFIG: (params= {})=> `${API.SUBSCRIPTION.BASE}/configuration`,
    CHECK_ENABLED:(params={})=>`${API.SUBSCRIPTION.CONFIG}/status`,
    USER_SUBSCRIPTION:(params={})=>`${API.SUBSCRIPTION.BASE}/users/${params.id}`,
    USER_SUBSCRIPTION_BY_USER_ID:(params={})=>`${API.SUBSCRIPTION.BASE}/users/user/${params.id}`,
    SELF_SUBSCRIPTION:(params={})=>`${API.SUBSCRIPTION.BASE}/users/self`,
    SWITCH_TO_FREE:(params={})=>`${API.SUBSCRIPTION.SELF_SUBSCRIPTION}/change-tariff/free`,
    CREATE_USER_SUBSCRIPTION:(params={})=>`${API.SUBSCRIPTION.BASE}/users/order-new-subscription`,
    TARIFFS:(params={})=>`${API.SUBSCRIPTION.BASE}/tariffs/all`,
  },
  RESOURCE_REPORT:{
    BASE:(params={})=>`${apiPrefix(params)}/resource-registration-report`,
    REPORT:(params={})=>`${API.RESOURCE_REPORT.BASE}/report?from=${params.from}&to=${params.to}&organization=${params.organization}`,
    REPORT_CSV:(params={})=>`${API.RESOURCE_REPORT.BASE}/excel?from=${params.from}&to=${params.to}&idOrg=${params.idOrg}&contextOrganization=${params.contextOrganization}`,
    REPORT4USER:(params={})=>`${apiPrefix(params)}/rating-user/report`,
    REPORT_COUNTRY4USER:(params={})=>`${apiPrefix(params)}/rating-user/report-country`,
    REPORT_CALCULATIONS4USER:(params={})=>`${apiPrefix(params)}/rating-user/report-calculations`
  },
  SUBSCRIPTION_REQUESTS:{
    BASE:(params={})=>`${API.SUBSCRIPTION.BASE}/requests`,
    ORDER_NEW_REQUEST:(params={})=>`${API.SUBSCRIPTION_REQUESTS.BASE}/order-new-request`,
    GET_NEW_OR_UNPROCESSED_REQUEST:(params={})=>`${API.SUBSCRIPTION_REQUESTS.BASE}/get-new-or-unprocessed-request`
  }
};

const addToString = (obj) => {
  each(obj, (item) => {
    const currentItem = item;

    if (isFunction(item)) {
      currentItem.toString = () => item();
      currentItem.indexOf = () => currentItem.toString().indexOf;
    } else if (isObject(item)) {
      addToString(item);
    }
  });
};

addToString(API);
